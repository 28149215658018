export const CONFIG = {
  stage: 'prod',
  baseApiUrl: 'https://api.prod.karstation.com',
};

export const AWS_CONFIG = {
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_ODXSEjZhu',
    identityPoolId: 'us-east-1:e4c31ac3-4dea-4952-9ab6-ad57a95d895b',
    userPoolWebClientId: '4lmap6l9n1j7o9fivln1oo526c',
    mandatorySignIn: false,
  },
  aws_appsync_graphqlEndpoint: 'https://mjgdggio45e6zgru5h26qcowyu.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};
