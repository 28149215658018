/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useEffect, useState } from 'react';
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Upload,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ulid } from 'ulid';
import { connect } from 'react-redux';
import html2pdf from 'html2pdf.js';
import printJS from 'print-js';
import ImgCrop from 'antd-img-crop';
import type { RcFile } from 'antd/es/upload';
import { findDescriptions } from '../../appointments/actions';
import Collapse3 from '../../../../components/Collapse3';
import {
  addJobList,
  deleteImage,
  deleteJobList,
  getImageUrl,
  getJobList,
  getSignedUrl,
  getWorkshopData,
  getWorkshopLogo,
  resolveTemplate,
  sendEmailNotification,
  updateBooking,
} from '../action';
import '../investigation.scss';
import { JOB_STATUS } from '../../../../enum/JobStatus';
import { USER_ROLES } from '../../../../enum/User';
import { BOOKING_TYPES } from '../../../../enum/BookingStatus';
import { hasRole } from '../../../../utils/commonUtil';

const Handlebars = require('handlebars');

const getBase64 = (file: RcFile): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = (error) => reject(error);
});

function MakeCheckIn(props: any) {
  const [form] = Form.useForm();
  const {
    item, status, onClose, getAllCheckin, userRoles, workShopId, workshop, configData,
  } = props;
  const [repairItemsOptions, setRepairItemsOptions] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [tableData, setTableData]: any[] = useState<any[]>([]);
  const [descriptions, setDescriptions] = useState<any[]>([]);
  const [isDescription, setIsDescription] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [fileList, setFileList] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [deleteStatus, setDeleteStatus] = useState();
  const [addJobStatus, setAddJobStatus] = useState<any>();
  const [report, setReport] = useState<any>();
  const [extendedReport, setExtendedReport] = useState<any>();
  const [isReportModalVisible, setReportModalVisible] = useState<boolean>(false);
  const [formattedTableData, setFormattedTableData] = useState<any[]>([]);
  const [comment, setComment] = useState<string>(' ');
  const [currency, setCurrency] = useState<any>();
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const { t } = useTranslation();

  const sortJobs = (array) => array.sort((a, b) => {
    const nameA = a.repairItem?.toUpperCase(); // ignore upper and lowercase
    const nameB = b.repairItem?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const timeConvert = (num) => {
    const hours = (num / 60);
    const rHours = Math.floor(hours);
    const minutes = (hours - rHours) * 60;
    const rMinutes = Math.floor(Math.round(minutes));
    if (rHours > 0) {
      if (rMinutes > 0) return `${rHours} hr(s) ${rMinutes} min(s)`;
      return `${rHours} hr(s)`;
    }
    return `${rMinutes} min(s)`;
  };

  const getFormattedTableData = () => {
    const formattedTempArray = tableData.map((val: any) => {
      const obj = JSON.parse(JSON.stringify(val));
      // eslint-disable-next-line no-restricted-globals
      if (val.price && !isNaN(obj.price)) obj.price = new Intl.NumberFormat(currency.languageCode, { style: 'currency', currency: currency.currency }).format(val.price);
      // eslint radix: "error"
      if (val.qtyHours) obj.qtyHours = timeConvert(parseInt(val.qtyHours, 10));
      // eslint-disable-next-line no-restricted-globals
      if (val.rate && !isNaN(obj.rate)) obj.rate = new Intl.NumberFormat(currency.languageCode, { style: 'currency', currency: currency.currency }).format(val.rate);
      // eslint radix: "error"
      if (val.stdHrsMin) obj.stdHrsMin = timeConvert(parseInt(val.stdHrsMin, 10));
      return obj;
    });
    setFormattedTableData(formattedTempArray);
  };

  const addOrRemoveTitlesToTableData = async (tdData:any) => {
    let jobsList = tdData.filter((tdItem) => !tdItem.title);
    const tempArray: any[] = [];
    let itemType = '';
    jobsList = sortJobs(jobsList);
    // eslint-disable-next-line array-callback-return
    jobsList.map((jobItem) => {
      if (itemType === jobItem.repairItem) {
        // @ts-ignore
        tempArray.push(jobItem);
      } else {
        itemType = jobItem.repairItem;
        if (itemType) tempArray.push({ title: itemType });
        tempArray.push(jobItem);
      }
    });
    setTableData(tempArray);
  };

  const sharedOnCell = (txt, cellItem) => ({
    props: {
      colSpan: cellItem.title ? 0 : 1,
    },
    children: <div>{txt}</div>,
  });

  const deletedJob = async (sortKey:any, bookingId:any) => {
    const deleteJobs = [];
    const obj = {
      bookingId,
      sortKey,
    };
    deleteJobs.push(obj);
    if (deleteJobs.length) {
      await deleteJobList(deleteJobs).then((res) => {
        if (!res) {
          message.error('Error');
        } else {
          setDeleteStatus(sortKey);
          message.success('Job Deleted Successfully');
        }
      });
    }
  };

  const columns = [
    {
      title: `${t('Description')}`,
      dataIndex: 'description',
      key: 'description',
      render: sharedOnCell,
    },
    {
      title: `${t('Standard Time')}`,
      dataIndex: 'stdHrsMin',
      key: 'stdHrsMin',
      render: sharedOnCell,
      align: 'center' as const,
    },
    {
      title: `${t('Rate')}`,
      dataIndex: 'rate',
      key: 'rate',
      render: sharedOnCell,
    },
    {
      title: `${t('Quantity')}`,
      dataIndex: 'qtyNo',
      key: 'qtyNo',
      render: sharedOnCell,
    },
    {
      title: `${t('price')}`,
      dataIndex: 'price',
      key: 'price',
      render: (text, objItem) => ({
        props: {
          style: objItem.title && {
            background: 'rgba(135,130,254,0.25)',
            fontWeight: 'bold',
            borderRadius: '4px',
          },
          colSpan: objItem.title ? 0 : 1,
          rowSpan: 1,
        },
        children: objItem.status !== JOB_STATUS.REJECTED
          ? <div>{text}</div>
          : (
            <div>
              <Tag
                style={{
                  textAlign: 'center',
                  alignSelf: 'center',
                  borderRadius: '8px',
                  fontSize: 14,
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginLeft: 13,
                  marginRight: 13,
                }}
                color="red"
              >
                Rejected
              </Tag>
            </div>
          ),
      }),
    },
    {
      dataIndex: 'delete',
      key: 'delete',
      width: '70px',
      render: (text, objItem) => ({
        props: {
          colSpan: objItem.title ? 0 : 1,
        },
        children: status === BOOKING_TYPES.CHECKIN_TO_DO && !hasRole([USER_ROLES.RECEPTIONIST], userRoles) ? (
          <>
            <DeleteOutlined onClick={() => deletedJob(objItem.sortKey, objItem.bookingId)} />
          </>
        ) : (<div style={{ display: 'none' }}> </div>),
      }),
    },
  ];

  // eslint-disable-next-line consistent-return
  const addTableValues = async () => {
    const {
      stdHrs, rate, description, quantity, selectItem,
    } = form.getFieldsValue();
    const standardTimeMin: number = parseInt(stdHrs.value, 10) * stdHrs.timeUnit;

    if (!description || description === '') {
      return message.error("Description can't be empty");
    }

    if (quantity < 0) {
      return message.error('Invalid Quantity Value');
    }

    const sortKey = ulid();
    let qty = 1;
    if (quantity) {
      qty = quantity;
    }

    const descriptionObj: any[] = descriptions.filter((items: any) => items.description.toUpperCase() === description.toUpperCase());
    const stdHrsMin: number = descriptionObj[0] ? Math.round(parseFloat(descriptionObj[0].stdHrsMin)) : standardTimeMin;
    const labourRateVal: number = descriptionObj[0] ? parseFloat(descriptionObj[0].labourRate) : parseFloat(rate);
    const price = (descriptionObj[0] && parseInt(stdHrs, 10)) ? descriptionObj[0].price * qty : (((stdHrsMin * labourRateVal) / 60) * qty).toFixed(2);

    if (labourRateVal < 0 || !labourRateVal) {
      return message.error('Invalid Rate Value');
    }

    if (stdHrsMin < 0 || !stdHrsMin) {
      return message.error('Invalid Std Hrs Value');
    }

    const newData = {
      sortKey,
      description,
      rate: labourRateVal,
      stdHrsMin,
      qtyHours: stdHrsMin,
      qtyNo: qty,
      repairItem: selectItem,
      price,
      checkStatus: true,
    };
    form.setFieldsValue({ description: '' });
    form.setFieldsValue({ rate: '' });
    form.setFieldsValue({ stdHrs: '' });
    form.setFieldsValue({ quantity: '1' });

    const newArr = [newData];
    const tdData = [...tableData, newData];
    addOrRemoveTitlesToTableData(tdData);
    const bookingId = `${item.id}`;
    const insertJobs = [];
    const viewData = [];
    const Data = newArr.filter((rdItem:any) => !rdItem.title);
    let bookingStdHrsMin = 0;
    console.log(bookingStdHrsMin);
    Data.map((job: any) => {
      const sortedKey = `${job.sortKey}`;
      bookingStdHrsMin += parseFloat(job.stdHrsMin) * parseFloat(job.qtyNo);
      if (!job.bookingId) {
        const obj = {
          bookingId,
          sortKey: job.sortKey,
          description: job.description,
          price: parseFloat(job.price),
          rate: parseFloat(job.rate),
          qtyHours: job.stdHrsMin,
          qtyNo: job.qtyNo,
          comment: job.comment,
          plateNumber: item.plateNumber,
          status: BOOKING_TYPES.TO_DO,
          repairItem: job.repairItem,
          updatedTime: new Date(),
          createdTime: new Date(),
        };
        insertJobs.push(obj);
        if (insertJobs.length) {
          addJobList(insertJobs).then((res) => {
            if (!res) {
              message.error('Error');
            } else {
              setAddJobStatus(sortedKey);
              message.success('Jobs Save Successfully');
            }
          });
        }
      }
      if (newArr[`${sortedKey}`] === true) {
        viewData.push(job);
      }
      return null;
    });
  };

  const getJobs = () => {
    getJobList(workShopId, item.sortKey).then((res:any) => {
      const jobs: any[] = [];
      res.data.getJobs.forEach((values: any) => {
        const temp: any = {
          ...values,
          stdHrsMin: values.qtyHours,
          rate: values.rate,
          qtyNo: values.qtyNo,
          checkStatus: true,
        };
        jobs.push(temp);
      });
      addOrRemoveTitlesToTableData(jobs);
    });
  };

  const descriptionSelecteced = () => {
    setIsDescription(false);
  };

  const descriptionNotSelecteced = () => {
    setIsDescription(true);
  };

  const getDescriptions = async (selectedItem) => {
    if (form.getFieldValue('description')) {
      form.resetFields(['description', 'rate', 'stdHrs', 'quantity']);
    }
    const res = await findDescriptions(workShopId, selectedItem);
    const array = [];
    // @ts-ignore
    res.data.getRepairItem.map((items) => array.push({ value: items.description }));
    setSuggestions(array);
    setDescriptions(res.data.getRepairItem);
  };

  const uploadImageListToS3 = async () => {
    const images = [];
    // eslint-disable-next-line array-callback-return
    fileList.map((image: any, index: number) => {
      const key = `${item.sortKey}_image_${index}.jpeg`;
      const contentType = image.type;
      // @ts-ignore
      images.push(key);
      if (image.originFileObj) {
        getSignedUrl(key, contentType).then((res) => {
          fetch(res.url, {
            method: 'PUT',
            headers: {
              'Content-Type': image.type,
            },
            body: image.originFileObj,
          })
            .then((ff) => ff.text())
            .then(() => console.log(`${image.name} uploaded successfully.`));
        });
      }
    });
    return images;
  };

  const submitCheckin = async () => {
    setLoading(true);
    const formData = form.getFieldsValue();
    const images = await uploadImageListToS3();
    const bookingId = `${item.id}`;
    const viewData = [];
    let bookingStdHrsMin: number = 0;
    const tdData = tableData.filter((rdItem:any) => !rdItem.title);
    tdData.map((job: any) => {
      const sortKey = `${job.sortKey}`;
      bookingStdHrsMin += parseFloat(job.stdHrsMin) * parseFloat(job.qtyNo);
      if (formData[`${sortKey}`] === true) {
        viewData.push(job);
      }
      return null;
    });

    // isEstimationEmpty
    if (!tdData.length) {
      setLoading(false);
      return message.error("Estimation form shouldn't be empty!");
    }

    const total = totalPrice;
    const bookingStdHrs = Math.round(bookingStdHrsMin);
    const updatedBookings = {
      ...item,
      status: BOOKING_TYPES.CHECKIN_IN_PROGRESS,
      images,
      workShopId,
      bookingStdHrsMin: bookingStdHrs,
      comment: formData.comment,
      updatedTime: new Date(),
      totalPrice,
      total,
      investigationCompletedTime: new Date(),
    };

    message.loading('Saving');
    await updateBooking(updatedBookings).then(async () => {
      message.destroy();
      message.success('Booking Saved');
      await sendEmailNotification(bookingId)
        .then(() => {
          message.success('Email Notification Sent');
        }).catch((e) => {
          message.error(e.message);
        });
    }).catch((err) => {
      console.log(err);
    });

    getAllCheckin();
    addOrRemoveTitlesToTableData(viewData);
    onClose();
    setLoading(false);
    return null;
  };

  const onImageChange = (info) => {
    const { fileList: newFileList, file } = info;
    setFileList(newFileList);
    if (file.status === 'done') {
      message.destroy();
      message.success('Updated successfully');
    }
  };

  const handlePreview = async (file: any) => {
    const data = file;
    if (!file.url && !file.preview) {
      data.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleCancel = () => setPreviewVisible(false);

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const loadImages = () => {
    setFileList([]);
    if (item.images) {
      item.images.forEach((image: any, index: number) => {
        getImageUrl(image, 'image/jpeg', 'getObject').then((imageUrl) => {
          const imageUrlObj = {
            uid: index,
            name: image,
            status: 'done',
            url: imageUrl.url,
          };
          // @ts-ignore
          setFileList((prevData) => [...prevData, imageUrlObj]);
        });
      });
    }
  };

  const onImageRemove = async (image) => {
    setLoading(true);
    const imgArray = item.images || [];
    if (imgArray.includes(image.name)) {
      const tempArray = [];
      fileList.map((img: any) => {
        if (img.name !== image.name) {
          // @ts-ignore
          tempArray.push(img.name);
        }
        return null;
      });
      await deleteImage(image.name);
      const updatedBooking = {
        ...item, workShopId, images: tempArray, updatedTime: new Date(),
      };
      await updateBooking(updatedBooking);
    }
    message.success('Image deleted successfully');
    setLoading(false);
  };

  const toggleReportModal = () => {
    setReportModalVisible(!isReportModalVisible);
  };

  const generateReport = () => {
    message.loading('Generating Report...').then();
    let reportTemplate;
    let jobsPerPage;
    if (status !== BOOKING_TYPES.TO_DO) {
      reportTemplate = '/reports/investigation/report-en.html';
      jobsPerPage = 8;
    } else {
      reportTemplate = '/reports/investigation/Job-Card/job-card-en.html';
      jobsPerPage = 15;
    }
    resolveTemplate(reportTemplate).then(async (temp) => {
      const template = Handlebars.compile(temp);
      const totalEstimation = formattedTableData.filter((tDItem) => tDItem.checkStatus || tDItem.title);
      const customLogo = workshop.logo && await getWorkshopLogo(workshop.logo, 'image/png', 'getObject');
      const workshopData: any = await getWorkshopData(workShopId);
      const {
        workshopName, address, contactNumber,
      } = workshopData.data.getWorkshop;
      // Display jobs(including titles) in report-view page 1
      const estimationTableData = totalEstimation.filter((job) => job.status !== 'Rejected').slice(0, jobsPerPage);
      let estimationTotal;
      if (totalEstimation.length < jobsPerPage) {
        estimationTotal = new Intl.NumberFormat(currency.languageCode, { style: 'currency', currency: currency.currency }).format(totalPrice);
      } else {
        estimationTotal = null;
      }
      const data = {
        ...item,
        totalEstimation: estimationTableData,
        total: estimationTotal,
        customLogo: customLogo.url || '/assets/logo.png',
        workshopName,
        address,
        contactNumber,
      };
      const result = template(data);

      setTimeout(() => {
        setReport(result);
        toggleReportModal();
      }, 1000);
    });
    let extendedReportTemplate;
    if (status !== BOOKING_TYPES.TO_DO) {
      extendedReportTemplate = '/reports/investigation/report-extended-en.html';
    } else {
      extendedReportTemplate = '/reports/investigation/Job-Card/job-card-extended-en.html';
    }
    resolveTemplate(extendedReportTemplate).then(async (temp) => {
      const template = Handlebars.compile(temp);
      const totalEstimation = formattedTableData.filter((tDItem) => tDItem.checkStatus || tDItem.title);
      // Pass rest of jobs(including titles) to estimation table in report-view page 2
      const estimationTableData = totalEstimation.filter((job) => job.status !== 'Rejected').slice(jobsPerPage);
      let estimationTotal;
      if (totalEstimation.length > (jobsPerPage - 1)) {
        estimationTotal = new Intl.NumberFormat(currency.languageCode, { style: 'currency', currency: currency.currency }).format(totalPrice);
      } else {
        estimationTotal = null;
      }
      const data = {
        totalEstimation: estimationTableData,
        total: estimationTotal,
      };
      const result = template(data);
      setExtendedReport(result);
    });
  };

  const downloadReport = async () => {
    const element = document.getElementById('report-view');
    const opt = {
      filename: 'total-estimation.pdf',
      html2canvas: {
        scale: 2,
        dpi: 300,
        letterRendering: true,
        useCORS: true,
      },
      jsPDF: {
        format: 'A4',
        orientation: 'portrait',
      },
      image: { type: 'png' },
    };
    html2pdf(element, opt);
  };

  const printReport = async () => {
    const element = document.getElementById('report-view');
    const opt = {
      jsPDF: {
        format: 'A4',
        orientation: 'portrait',
      },
      html2canvas: {
        scale: 2,
        dpi: 300,
        letterRendering: true,
        useCORS: true,
      },
      image: { type: 'png' },
    };
    const res = await html2pdf()
      .set(opt)
      .from(element)
      .outputImg();

    printJS({
      printable: res.src,
      type: 'image',
      properties: ['prop1', 'prop2', 'prop3'],
    });
  };

  const calculateTotalPrice = () => {
    let tPrice: number = 0;
    tableData.forEach((job: any) => {
      if (!job.title && job.status !== JOB_STATUS.REJECTED && job.checkStatus) {
        tPrice += parseFloat(job.price);
      }
    });
    setTotalPrice(tPrice);
  };

  useEffect(() => {
    if (tableData && currency) {
      getFormattedTableData();
      calculateTotalPrice();
    }
  }, [tableData, currency]);

  useEffect(() => {
    if (workShopId && currency) {
      loadImages();
      getJobs();
    }
  }, [workShopId, currency, deleteStatus, addJobStatus]);

  useEffect(() => {
    console.log(userRoles);
    if (configData) {
      setRepairItemsOptions(configData?.repairItems);
      setCurrency(configData?.currency);
    }
  }, [configData]);

  useEffect(() => {
    if (item && item.comment) setComment(String(item.comment));
  }, [item]);

  useEffect(() => {
    console.log(userRoles.includes());
  });

  return (
    <>
      <Form form={form} onFinish={submitCheckin} layout="vertical" requiredMark={false}>
        <Form.Item label={t('Images')} className="ka_lbl_bkfrm" name="selectItem" id="repairItems">
          <Upload disabled />
          <ImgCrop grid aspect={1280 / 720} quality={0.4}>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={onImageChange}
              onPreview={handlePreview}
              customRequest={dummyRequest}
              onRemove={onImageRemove}
              accept=".png, .jpg, .jpeg"
              maxCount={5}
              disabled={isLoading || status !== BOOKING_TYPES.CHECKIN_TO_DO}
            >
              {fileList.length < 5 && '+ Upload'}
            </Upload>
          </ImgCrop>
          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
            width={800}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Form.Item>
        {status === BOOKING_TYPES.CHECKIN_IN_PROGRESS && (
        <Col>
          <Row>
            <Col className="col-title" span={6}>{t('Comments')}</Col>
            <Col className="col-value" span={18}>{comment}</Col>
          </Row>
        </Col>
        )}
        {status === BOOKING_TYPES.TO_DO && (
        <Col>
          <Row>
            <Col className="col-title" span={6}>{t('Comments')}</Col>
            <Col className="col-value" span={18}>{comment}</Col>
          </Row>
        </Col>
        )}
        {status === BOOKING_TYPES.CHECKIN_TO_DO && (
          <Row>
            <Col span={24}>
              <Form.Item label={t('Comments')} className="ka_lbl_bkfrm" id="comment" name="comment">
                <Input className="ks_int_bk ks_int_bkfrm" type="text" placeholder={t('Add Comments')} />
              </Form.Item>
            </Col>
          </Row>
        )}
        {status === BOOKING_TYPES.CHECKIN_TO_DO && (
          <Row>
            <Col span={24}>
              <Form.Item label={t('Repair Items')} className="ka_lbl_bkfrm" name="selectItem" id="repairItems">
                <Select
                  className="ks_drp_vcl-ty selection_syl"
                  placeholder={t('Select an Item')}
                  options={repairItemsOptions}
                  onChange={getDescriptions}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {status === BOOKING_TYPES.CHECKIN_TO_DO && (
          <Row>
            <Col span={24}>
              <Form.Item label={t('Description')} className="ka_lbl_bkfrm" id="description" name="description">
                <AutoComplete
                  className="ks_drp_vcl-ty selection_syl"
                  options={suggestions}
                  placeholder={t('Type Description')}
                  onSelect={descriptionSelecteced}
                  onChange={descriptionNotSelecteced}
                  filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {status === BOOKING_TYPES.CHECKIN_TO_DO && (
          <Row>
            <Col span={6}>
              <Form.Item label={t('Standard Time')}>
                <Input.Group compact>
                  <Form.Item
                    name={['stdHrs', 'value']}
                    style={{ display: 'inline-block', width: 'calc(60% - 8px)' }}
                  >
                    <Input type="number" min={1} className="ks_int_bk ks_int_bkfrm" placeholder={t('Standard Time')} disabled={!isDescription} />
                  </Form.Item>
                  <Form.Item
                    name={['stdHrs', 'timeUnit']}
                    style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
                  >
                    <Select
                      className="ks_int_bk ks_int_bkfrm"
                      placeholder={t('Unit..')}
                      size="middle"
                      disabled={!isDescription}
                    >
                      <Select.Option value={1}>min</Select.Option>
                      <Select.Option value={60}>hours</Select.Option>
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label={t('Rate')} className="ka_lbl_bkfrm" name="rate" id="rate">
                <Input className="ks_int_bk ks_int_bkfrm" placeholder={t('Rate')} disabled={!isDescription} />
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label={t('Quantity')} className="ka_lbl_bkfrm" name="quantity" id="quantity">
                <Input className="ks_int_bk ks_int_bkfrm" type="number" placeholder={t('Quantity')} defaultValue="1" />
              </Form.Item>
            </Col>
            <Col span={3} style={{ paddingTop: '27px', float: 'right', textAlign: 'right' }}>
              <Button
                onClick={addTableValues}
                style={{ backgroundColor: '#131A54' }}
                shape="circle"
                icon={<PlusOutlined style={{ color: 'white' }} />}
                disabled={hasRole([USER_ROLES.RECEPTIONIST], userRoles)}
                size="large"
              />
            </Col>
          </Row>
        )}
        <Row className="tab-row">
          <br />
          <Collapse3
            content1={(
              <Table dataSource={formattedTableData} columns={columns} pagination={false}
                footer={() => (
                  <Row>
                    <Col span={20}>
                      <div className="ka_lbl_bkfrm">TOTAL:</div>
                    </Col>
                    <Col span={4} className="total-est">
                      {new Intl.NumberFormat(currency.languageCode, { style: 'currency', currency: currency.currency }).format(totalPrice)}
                    </Col>
                  </Row>
                )}
              />
            )}
            header1={t('Total Estimation')}
          />
        </Row>
        <Row style={{ float: 'right', marginTop: '1rem' }}>
          <Button
            type="primary"
            onClick={generateReport}
            style={{ borderRadius: '8px', marginRight: 20 }}
            disabled={isLoading || status === BOOKING_TYPES.CHECKIN_IN_PROGRESS}
          >
            {' '}
            {status === BOOKING_TYPES.CHECKIN_TO_DO ? t('Generate Report') : t('Generate Job Card')}
          </Button>
          {status !== BOOKING_TYPES.TO_DO
            && (
              <Button
                type="primary"
                htmlType="submit"
                style={{ borderRadius: '8px', marginRight: 20 }}
                disabled={hasRole([USER_ROLES.RECEPTIONIST], userRoles)}
              >
                {isLoading && <Spin size="small" />}
                {' '}
                {status === BOOKING_TYPES.CHECKIN_TO_DO ? `${t('Submit to Customer')}` : `${t('Resend Email')}`}
              </Button>
            )}
          {status === BOOKING_TYPES.TO_DO && (
            <Button type="primary" style={{ borderRadius: '8px' }} disabled>
              {isLoading && <Spin size="small" />}
              {' '}
              {t('Approved')}
            </Button>
          )}
        </Row>
      </Form>
      <Modal bodyStyle={{ overflowX: 'scroll' }}
        width={1000}
        title={status === 'To Do' ? 'Job Card' : 'Estimation'}
        visible={isReportModalVisible}
        onCancel={toggleReportModal}
        footer={[
          <Button key="1" onClick={printReport}>Print</Button>,
          <Button key="2" onClick={downloadReport}>Download</Button>,
          <Button key="3" type="primary" onClick={toggleReportModal}>
            Cancel
          </Button>,
        ]}
      >
        <div id="report-view" dangerouslySetInnerHTML={{ __html: report + extendedReport }} />
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    userRoles: state.userData.userRoles,
    workshop: state.userData.workshop,
    configData: state.userData.configData,
  };
}

export default connect(mapStateToProps, {})(MakeCheckIn);
