// eslint-disable-next-line import/prefer-default-export
export const TRANSLATIONS_EN = {
  // Booking page translations
  'Make an Appointment': 'Make an Appointment',
  topic: 'Make an Appointment',
  'Repair Items': 'Repair Items',
  'Select an Item': 'Select an Item',
  'Unit..': 'Unit..',
  'Type Description': 'Type Description',
  'Please enter vehicle make': 'Please enter vehicle make',
  'Please enter insurance details': 'Please enter insurance details',
  'Please enter vehicle owner name': 'Please enter vehicle owner name',
  'Please enter contact number with 10 digits': 'Please enter contact number with 10 digits',
  'Please enter contact number': 'Please enter contact number',
  'Please enter a valid email address': 'Please enter a valid email address',
  'Please enter email': 'Please enter email',
  'Please enter vehicle model': 'Please enter vehicle model',
  'Please enter year of manufacture': 'Please enter year of manufacture',
  'Please enter year of register': 'Please enter year of register',
  'Please enter engine number': 'Please enter engine number',
  'Please enter chassis number': 'Please enter chassis number',
  'Please enter mileage': 'Please enter mileage',
  'Enter Mileage': 'Enter Mileage',
  'Please enter color code': 'Please enter color code',
  'Please enter tier size': 'Please enter tier size',
  'Please enter battery rate': 'Please enter battery rate',
  'Please enter fuel type': 'Please enter fuel type',
  'Salary Details': 'Salary Details',
  'EPF Employee Contribution': 'EPF Employee Contribution',
  'ETF Company Contribution': 'ETF Company Contribution',
  'EPF Company Contribution': 'EPF Company Contribution',
  'Please enter license details': 'Please enter license details',
  'Plate Number': 'Vehicle Plate Number',
  'Job Type': 'Job Type',
  'Select a Job Type': 'Select a Job Type',
  'Vehicle Make': 'Vehicle Make',
  'Enter Vehicle Make': 'Enter Vehicle Make',
  date: 'Date',
  time: 'Time',
  SDate: 'Select a Date',
  Stime: 'Select a Time',
  'Add New Vehicle': 'Add New Vehicle',
  'More Information': 'More Information',
  'Repair Item': 'Repair Item',
  'Please select Job type': 'Please select Job type',
  'Please select a Booking Date': 'Please select a Booking Date',
  'Please select a Booking Time': 'Please select a Booking Time',
  'Select time': 'Select time',
  Sitem: 'Select an Item',
  Description: 'Description',
  rate: 'Rate',
  'Total Estimation': 'Total Estimation',
  'Type/Select a Description': 'Type/Select a Description',
  'Standard Time': 'Standard Time',
  'Vehicle Details': 'Vehicle Details',
  'Select Year': 'Select Year',
  'Owner Details': 'Owner Details',
  'User Details': 'User Details',
  'Vehicle Number': 'Vehicle Number',
  'Vehicle Model': 'Vehicle Model',
  'Year Of Manufacture': 'Year Of Manufacture',
  Mileage: 'Mileage',
  'Year of Register': 'Year of Register',
  enterManu_Year: 'Enter Year of Manufacture',
  'Engine Number': 'Engine Number',
  'Enter Engine Number': 'Enter Engine Number',
  'Chassis Number': 'Chassis Number',
  'Enter Chassis Number': 'Enter Chassis Number',
  battery: 'Battery Type/Number',
  modelNo: 'Model Number',
  'Tyre Size': 'Tyre Size',
  'Enter Tyre Size': 'Enter Tyre Size',
  'Owner Name': 'Owner Name',
  'Enter Owner Name': 'Enter Owner Name',
  'Contact Number': 'Contact Number',
  'Enter Contact Number': 'Enter Contact Number',
  Email: 'Email',
  'Enter Email': 'Enter Email',
  Save: 'Save',
  Cancel: 'Cancel',
  home: 'Dashboard',
  booking: 'Bookings',
  'Job in progress': 'Jobs',
  createBooking: 'Appointments',
  inventory: 'Inventory',
  'Add Item': 'Add Item',
  'Edit Item': 'Edit Item',
  'Goods Received': 'Goods Received',
  'Download Template': 'Download Template',
  admin: 'Admin',
  jobCreation: 'Job Creation',
  regiNo: 'Registration Number',
  'Enter Plate Number': 'Enter Plate Number',
  'Enter Vehicle Model': 'Enter Vehicle Model',
  'Color Code': 'Color Code',
  'Enter Color Code': 'Enter Color Code',
  'Enter Battery Rate': 'Enter Battery Rate',
  'Fuel Type': 'Fuel Type',
  'Enter Fuel Type': 'Enter Fuel Type',
  'Enter License Number': 'Enter License Number',
  'License Details': 'License Details',
  'Enter License Details': 'Enter License Details',
  'Select Fuel Type': 'Select Fuel Type',
  'Select Insurance Company': 'Select Insurance Company',
  'Vehicle Registration Number': 'Vehicle Registration Number',
  'Battery Replacement Date': 'Battery Replacement Date',
  'Enter Battery Replacement Date': 'Enter Battery Replacement Date',
  licenseNumber: 'License Number',
  'License Renewal Date': 'License Renewal Date',
  'Insurance Details': 'Insurance Details',
  insuranceNumber: 'Insurance Number',
  'Insurance Renewal Date': 'Insurance Renewal Date',
  'Vehicle User Name': 'Vehicle User Name',
  'Enter Insurance Details': 'Enter Insurance Details',
  'Enter User Name': 'Enter User Name',
  submit: 'Submit',
  logOut: 'Logout',
  stdHours: 'Standard Time',
  Quantity: 'Quantity',
  Total: 'TOTAL:',
  price: 'Price',
  // translations for global part search
  'Global Parts Search': 'Global Parts Search',
  // Job Creation page translations
  'Select File': 'Select File',
  'Edit Job Details': 'Edit Job Details',
  'Applicable Vehicle Make': 'Applicable Vehicle Make',
  'Applicable Vehicle Model': 'Applicable Vehicle Model',
  'Checklist Have (1) / Don’t Have (0)': 'Checklist Have (1) / Don’t Have (0)',
  LH: 'LH',
  RH: 'RH',
  FRT: 'FRT',
  RER: 'RER',
  'Price Rs.': 'Price Rs.',
  'Labour Rate Type': 'Labour Rate Type',
  'Labour rate Rs.': 'Labour rate Rs.',
  'STD Hrs (min)': 'STD Hrs (min)',
  'Assistance Allowed (1) / Not Allowed (0)': 'Assistance Allowed (1) / Not Allowed (0)',
  'Main Tech Incentive': 'Main Tech Incentive',
  'Assistant Tech Incentive (Rs.)': 'Assistant Tech Incentive (Rs.)',
  'Job Category': 'Job Category',
  'Main Tech Group 1': 'Main Tech Group 1',
  'Main Tech Group 2': 'Main Tech Group 2',
  'Assistant Tech Group 2': 'Assistant Tech Group 2',
  'Active Status': 'Active Status',
  'Tax 1 (VAT %)': 'Tax 1 (VAT %)',
  'Tax 2 (NBT %)': 'Tax 2 (NBT %)',
  'Tax 3 (Other %)': 'Tax 3 (Other %)',
  'Income Account Name': 'Income Account Name',
  'P & L': 'P & L',
  'INSTRUCTIONS / Notes': 'INSTRUCTIONS / Notes',
  'Images (Hyper Link)': 'Images (Hyper Link)',
  'Videos (HyperLink)': 'Videos (HyperLink)',
  'Add Job Details': 'Add Job Details',
  'Vehicle Category': 'Vehicle Category',
  'Enter Applicable Vehicle Make': 'Enter Applicable Vehicle Make',
  'Enter Applicable Vehicle Model': 'Enter Applicable Vehicle Model',
  'Enter Vehicle Category': 'Enter Vehicle Category',
  'Enter Check List Have (1) / Don’t Have (0)': 'Enter Check List Have (1) / Don’t Have (0)',
  'Enter Description': 'Enter Description',
  'Enter LH': 'Enter LH',
  'Enter RH': 'Enter RH',
  'Enter FRT': 'Enter FRT',
  'Enter RER': 'Enter RER',
  'Enter Price': 'Enter Price',
  'Select a person': 'Select a person',
  'Enter Rate': 'Enter Rate',
  'Enter Enter STD hours': 'Enter STD hours',
  'Enter Tax': 'Enter Tax',
  'Enter Main Tech Incentive': 'Enter Main Tech Incentive',
  'Enter Assistant Tech Incentive': 'Enter Assistant Tech Incentive',
  'Enter Job Category': 'Enter Job Category',
  'Enter Main Tech Group 1': 'Enter Main Tech Group 1',
  'Enter Main Tech Group 2': 'Enter Main Tech Group 2',
  'Enter Assistant Tech Group 2': 'Enter Assistant Tech Group 2',
  'Enter Active Status': 'Enter Active Status',
  'Enter Income Account Name': 'Enter Income Account Name',
  'Enter P & L': 'Enter P & L',
  'Enter INSTRUCTIONS / Notes': 'Enter INSTRUCTIONS / Notes',
  'Enter Images (Hyper Link)': 'Enter Images (Hyper Link)',
  'Enter Videos (HyperLink)': 'Enter Videos (HyperLink)',
  upload: 'Upload',
  'Please Enter Applicable Vehicle Make': 'Please Enter Applicable Vehicle Make',
  'Please Enter Applicable Vehicle Model': 'Please Enter Applicable Vehicle Model',
  'Please Enter Vehicle Category': 'Please Enter Vehicle Category',
  'Input should be a one digit number': 'Input should be a one digit number',
  'Please Enter Description': 'Please Enter Description',
  'Please Enter LH': 'Please Enter LH',
  'Please Enter RH': 'Please Enter RH',
  'Please Enter FRT': 'Please Enter FRT',
  'Please Enter RER': 'Please Enter RER',
  'Please Enter Price': 'Please Enter Price',
  'Please Enter Labour Rate Type': 'Please Enter Labour Rate Type',
  'Please Enter Rate': 'Please Enter Rate',
  'Please Enter Enter STD hours': 'Please Enter Enter STD hours',
  'Input should be a one digit number ': 'Input should be a one digit number ',
  'Please Enter Main Tech Incentive': 'Please Enter Main Tech Incentive',
  'Please Enter Assistant Tech Incentive': 'Please Enter Assistant Tech Incentive',
  'Please Enter Job Category': 'Please Enter Job Category',
  'Please Enter Main Tech Group 1': 'Please Enter Main Tech Group 1',
  'Please Enter Main Tech Group 2': 'Please Enter Main Tech Group 2',
  'Please Enter Assistant Tech Group 2': 'Please Enter Assistant Tech Group 2',
  'Please Enter Active Status': 'Please Enter Active Status',
  'Please Enter Tax': 'Please Enter Tax',
  'Please Enter Income Account Name': 'Please Enter Income Account Name',
  'Please Enter P & L': 'Please Enter P & L',
  'Please Enter INSTRUCTIONS / Notes': 'Please Enter INSTRUCTIONS / Notes',
  'Please Enter Images (Hyper Link)': 'Please Enter Images (Hyper Link)',
  jobName: 'Job Name',
  'Input should be a number ': 'Input should be a number ',
  'Please Enter Checklist Have (1) / Don’t Have (0)': 'Please Enter Checklist Have (1) / Don’t Have (0)',
  subSection: 'Sub Section',
  sectionDescription: 'Describe about each and every job description one job at a time',
  qty: 'Qty or hours',
  addJob: 'Add Job',
  jobList: 'Job List',
  'Please enter a valid number': 'Please enter a valid number',
  Edit: 'Edit',
  'Upload File': 'Upload File',
  // validation messages
  regiNoMessage: 'Please input the Vehicle Number',
  jobTypeMessage: 'Please select Job type',
  bookingDateMessage: 'Please select a Booking Date',
  bookingTimeMessage: 'Please select a Booking Time',
  selectItemMessage: 'Please select a Item',
  inputRateMessage: 'Please input rate',
  qtyHoursMessae: 'Please input qty_Hours',

  /* tour messages */
  'Skip Button': 'Skip',
  // dashboard tour
  heyThere: 'Hey there..!',
  welcomeMessage: 'Welcome to your workshop.',
  areYouReady: 'Are you ready to discover your workshop..?',
  selectLanguageHere: 'Select your most preferred language here..',
  viewNotification: 'View notifications',
  viewNotificationHere: 'You can view notification from here..',
  youAreonDashboard: 'You are on the Workshop Dashboard !',

  // create booking tour
  'Are you ready to create a Booking ?': 'Are you ready to create a Booking ?',
  'Make an appointment': 'Make an appointment',
  'Select a date from the calendar view': 'Select a date from the calendar view',
  'Fill the form to make an appointment': 'Fill the form to make an appointment',
  'Add new Vehicle': 'Add new Vehicle',
  'Click here to add a new vehicle, if the vehicle is not registerd in Karstation': 'Click here to add a new vehicle, if the vehicle is not registerd in Karstation',
  'Search vehicle': 'Search vehicle',
  'If the vehicle is already registered, search vehicle with plate number': 'If the vehicle is already registered, search vehicle with plate number',
  // dashboard
  dashboard: 'Dashboard',
  // view task
  'Booking Details': 'Booking Details',
  Jobtype: 'Job Type',
  Year: 'Year',
  'Battery Rate': 'Battery Rate',
  Name: 'Name',
  Platenumber: ' Vehicle Plate Number',
  VehicleMake: ' Vehicle Make',
  Vmodel: ' Vehicle Model',
  Enginenumber: ' Engine Number',
  Chassisnumber: ' Chassis Number',
  Tyresize: ' Tyre Size',
  Batteryrate: ' Battery Rate',
  Fueltype: ' Fuel Type',
  Contactnumber: ' Contact Number',
  Standardhoursmin: ' Standard Time',
  Qty: 'Qty',
  Rate: ' Rate',
  Price: 'Price',
  Status: 'Status',
  Assignee: 'Assignee',
  Comment: ' Comment',
  Qcstatus: ' QC Status',
  'Booking History': 'Booking History',

  // view task investigation
  'Make a Check-In': 'Make a Check-In',
  Images: 'Images',
  quickjobs: 'Quick Jobs',

  // chech in page
  Investigation: 'Investigation',
  'Add Comments': 'Add Comments',
  'Generate Report': 'Generate Report',
  Approved: 'Approved',
  Comments: 'Comments',
  'Submit to Customer': 'Submit to Customer',
  'Resend Email': 'Resend Email',
  // inventory page
  Category: 'Category',
  PriceRS: ' Price',
  'Part Number': 'Part Number',
  'Part Name': 'Part Name',
  'Other Names': 'Other Names',
  'Description / Details': 'Description / Details',
  'Warehouse Type': 'Warehouse Type',
  'Bin Location': 'Bin Location',
  'Anticipated Usage': 'Anticipated Usage',
  Action: 'Action',
  'Item Type': 'Item Type',
  'Supplier Information': 'Supplier Information',
  'Goods In': 'Goods In',
  'Goods Out': 'Goods Out',
  'Warranty Details': 'Warranty Details',
  'Browse Image (Optional)': 'Browse Image (Optional)',
  'Min Order Quantity To Supplier': 'Min Order Quantity To Supplier',
  'Select Item Type': 'Select Item Type',
  'Enter Part Number': 'Enter Part Number',
  'Enter Part Name': 'Enter Part Name',
  'Enter Supplier Information': 'Enter Supplier Information',
  'Enter Other Names': 'Enter Other Names',
  'Enter Warehouse Type': 'Enter Warehouse Type',
  'Enter Bin Location': 'Enter Bin Location',
  'Enter Anticipated Usage': 'Enter Anticipated Usage',
  'Enter Warranty Details': 'Enter Warranty Details',
  'Enter Min Order Quantity-To-Supplier': 'Enter Min Order Quantity-To-Supplier',
  'Browse Image': 'Browse Image',
  'Labour Rate': 'Labour Rate',
  'Checklist Availability': 'Checklist Availability',
  // CheckListAvailabilit: ' Check List Availabilit',
  'Assistance Allowed': 'Assistance Allowed',
  'Assistant Tech Incentive': 'Assistant Tech Incentive',
  'Tax 2 (NBT 2)': 'Tax 2 (NBT 2)',
  'Tax 3 (Other)': 'Tax 3 (Other)',
  'Videos (Hyper Link)': 'Videos (Hyper Link)',
  Inventory: ' Inventory',
  Jobs: ' Jobs',
  // staff page
  EmployeeID: ' Employee ID',
  Next: 'Next',
  Back: 'Back',
  'Date of Birth': 'Date of Birth',
  Address: 'Address',
  'Employee Name': 'Employee Name',
  'Enter Account Holder Name': 'Enter Account Holder Name',
  'Enter Employee Bank': 'Enter Employee Bank',
  'Enter The Branch': 'Enter The Branch',
  'Enter Account Number': 'Enter Account Number',
  'Enter Basic Salary': 'Enter Basic Salary',
  'Enter EPF': 'Enter EPF',
  'Enter ETF': 'Enter ETF',
  'Please Enter Account Holder Name': 'Please Enter Account Holder Name',
  'Please Enter Employee Bank': 'Please Enter Employee Bank',
  'Please Enter Branch': 'Please Enter Branch',
  'Please Enter Account Number': 'Please Enter Account Number',
  'Please Enter Basic Salary': 'Please Enter Basic Salary',
  'Enter Employee Address': 'Enter Employee Address',
  Role: 'Role',
  'Please Enter EPF': 'Please Enter EPF',
  'Please Enter ETF': 'Please Enter ETF',
  'G.C.E. O/L Certificate  [NVQ4]': 'G.C.E. O/L Certificate  [NVQ4]',
  'G.C.E A/L Certificate  [NVQ5]': 'G.C.E A/L Certificate  [NVQ5]',
  'NDT/HNDE/Diploma in Automobile Engineering  [NVQ 6]': 'NDT/HNDE/Diploma in Automobile Engineering  [NVQ 6]',
  'BSc (Engineering)/BEng Automobile [NVQ 7]': 'BSc (Engineering)/BEng Automobile [NVQ 7]',
  'Msc Engineering/ MEng Automobile': 'Msc Engineering/ MEng Automobile',
  'Other Professional  Registrations': 'Other Professional  Registrations',
  'Incorporated Engineer Registration': 'Incorporated Engineer Registration',
  'Charted Engineer Registration': 'Charted Engineer Registration',
  Browse: 'Browse',
  'Please Enter Employee Name': 'Please Enter Employee Name',
  'Please Select Employee Role': 'Please Select Employee Role',
  'Please Enter Employee NIC Number': 'Please Enter Employee NIC Number',
  'Please Enter Employee Contact Number': 'Please Enter Employee Contact Number',
  'Please Enter User Name': 'Please Enter User Name',
  'Please Select Status': 'Please Select Status',
  'Contact No': 'Contact No',
  'Enter Employee Name': 'Enter Employee Name',
  'Select Employee Role': 'Select Employee Role',
  'Enter NIC Number': 'Enter NIC Number',
  'Select Date of Birth': 'Select Date of Birth',
  Username: 'Username',
  'Please Enter Date of Birth': 'Please Enter Date of Birth',
  Staff: ' Staff',
  // workshops
  AddNewWorkshops: ' Add New Workshop',
  Workshops: ' Workshops',
  WorkshopID: ' Workshop ID',
  WorkshopName: ' Workshop Name',
  WorkshopRegistrationNumber: ' Workshop Registration Number',
  WorkshopContactDetails: ' Workshop Contact Details',
  // main page
  History: ' History',
  GlobalParts: ' Global Parts',
  'Add an Employee': 'Add an Employee',
  EmployeeName: ' Employee Name',
  'Employee Role': 'Employee Role',
  'NIC Number': 'NIC Number',
  'Date of Birth (Optional)': 'Date of Birth (Optional)',
  'Address (Optional)': 'Address (Optional)',
  'User Name': 'User Name',
  General: ' General',
  BrowseImage: ' Browse Image (Optional',
  Salary: ' Salary',
  'Account Holder Name': 'Account Holder Name',
  'Employee Bank': 'Employee Bank',
  Branch: 'Branch',
  'Account Number': 'Account Number',
  'Basic Salary': 'Basic Salary',
  Incentives: 'Incentives',
  Qualifications: ' Qualifications',
  'Configuration Data': 'Configuration Data',
  'Job Types': 'Job Types',
  Password: ' Password',
  Bank: 'Bank',
  Currency: 'Currency',
  'By configuring data you will be able to add ‘Job Types ’ and ‘Repair items’ to the system.': 'By configuring data you will be able to add ‘Job Types ’ and ‘Repair items’ to the system.',
  'CHECK-IN': 'CHECK-IN',
  'PENDING CUSTOMER APPROVAL': 'PENDING CUSTOMER APPROVAL',
  'JOBS IN QUEUE': 'JOBS IN QUEUE',
  breakdown: 'Breakdown',
  repair: 'Repair',
  service: 'Service',
  accident: 'Accident',
  View: 'View',
  Type: 'Type',
  'Passed with conditions': 'Passed with QC failed jobs',
  Rejected: 'Rejected',
  Search: 'Search',
  From: 'From',
  To: 'To',
  'IN PROGRESS': 'IN PROGRESS',
  'QUALITY CONTROL': 'QUALITY CONTROL',
  PAYMENTS: 'PAYMENTS',
  'Jobs In Queue': 'Jobs In Queue',
  'In Progress': 'In Progress',
  'Quality Control': 'Quality Control',
  'Pending Payment': 'Pending Payment',
  Accident: 'Accident',
  Repair: 'Repair',
  Breakdown: 'Breakdown',
  Service: 'Service',
  'Standard Hours(min)': 'Standard Hours(min)',
  Start: 'Start',
  Pause: 'Pause',
  Done: 'Done',
  'Select the Reason': 'Select the Reason',
  Pay: 'Pay',
  Cash: 'Cash',
  Online: 'Online',
  'QC Status': 'QC Status',
  'Select reason': 'Select reason',
  Pass: 'Pass',
  Fail: 'Fail',
  'Assign to..': 'Assign to..',
  'Submit to Pending Payment': 'Submit to Pending Payment',
  'Submit to In-Progress': 'Submit to In-Progress',
  'Submit to QC': 'Submit to QC',
  Invoice: 'Invoice',
  Settings: 'Settings',
  'To proceed there should be at least one job started': 'To proceed there should be at least one job started',
  'Back to Job In Queue': 'Back to Job In Queue',
  'To Submit there should be at least one job started or done': 'To Submit there should be at least one job started or done',
  'To Do': 'Jobs in Queue',
  Quickjobs: 'Quick Job',
  // new words
  'Item ID': 'Item ID',
  'Invoice number': 'Invoice number',
  Units: 'Units',
  'Min Issue Qty': 'Min Issue Qty',
  Invoicing: 'Invoicing',
  'Please Enter Item ID': 'Please Enter Item ID',
  'Enter Item ID': 'Enter Item ID',
  'Please Enter Invoice number': 'Please Enter Invoice number',
  'Enter Invoice number': 'Enter Invoice number',
  'Please Enter Quantity': 'Please Enter Quantity',
  'Enter Quantity': 'Enter Quantity',
  'Min Order Quantity': 'Min Order Quantity',
  'Please Enter Min Order Quantity': 'Please Enter Min Order Quantity',
  'Enter Min Order Quantity': 'Enter Min Order Quantity',
  'Min Issue Quantity': 'Min Issue Quantity',
  'Please Enter Min Issue Quantity': 'Please Enter Min Issue Quantity',
  'Enter Min Issue Quantity': 'Enter Min Issue Quantity',
  'Please Enter Units': 'Please Enter Units',
  'Select Unit': 'Select Unit',
  // new
  'Insurance Company': 'Insurance Company',
  'Insurance Reneval Date': 'Insurance Reneval Date',
  'Please input item name': 'Please input item name',
  'Please select a group': 'Please select a group',
  'Repair Item Groups': 'Repair Item Groups',
  'Please select form': 'Please select form',
  'Select the form': 'Select the form',
  'Export CSV': 'Export. CSV',
};
