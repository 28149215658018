import React, { useEffect, useState } from 'react';
import {
  Button, Col, Drawer, Input, Popover, Row, Table, Tag, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { getAllEmployee, searchEmployeeList } from './action';
import './staff.scss';
import AddEmployee from './components/AddEmployee';
import ViewEmployee from './components/ViewEmployee';
import EditEmployee from './components/EditEmployee';
import Loading from '../../../Loading';
import { ACTION_TYPE } from '../../../enum/ConfigurationData';

export const StaffPage = ({ workShopId, userRoles }: any) => {
  const { Title } = Typography;

  const [staff, setStaff] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<any>(undefined);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [selectedType, setSelectedType] = useState<ACTION_TYPE>(ACTION_TYPE.VIEW);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const { t } = useTranslation();

  const getAllEmployeeData = async () => {
    let limit = 20;
    let nextToken: any = null;
    if (pageNumber > 1) {
      limit = 10;
      nextToken = data.nextToken;
    }
    await getAllEmployee(workShopId, limit, nextToken).then((res: any) => {
      const newData = (res && res.data.getEmployees) || [];
      const newTableData = newData.employees || [];
      nextToken = newData.nextToken || null;
      // @ts-ignore
      setStaff((prevState: any) => [...prevState, ...newTableData]);
      setData(newData);
      setLoading(true);
      if (newTableData.length < 10 || !nextToken) {
        setHasMore(false);
      }

      if (nextToken) {
        setPageNumber(pageNumber + 1);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchData = () => {
    if (workShopId) {
      setStaff([]);
      getAllEmployeeData();
    }
  };

  useEffect(() => {
    fetchData();
  }, [workShopId]);

  const tablePagination = (e) => {
    if (e + 1 >= pageNumber && hasMore) {
      getAllEmployeeData();
    }
  };

  const onSearch = (e) => {
    let keyword = e.target.value;
    keyword = keyword.toLowerCase();
    if (keyword === '' || keyword === null) {
      getAllEmployeeData();
    } else {
      searchEmployeeList(workShopId, keyword).then((res: any) => {
        setStaff(res.data.searchEmployee);
      });
    }
  };

  const triggerModal = (action: ACTION_TYPE, item: any = undefined) => {
    setSelectedItem(item);
    setSelectedType(action);
    setModalVisible(true);
  };

  const columnsData = [
    {
      title: `${t('Name')}`,
      dataIndex: 'name',
      width: '50px',
    },
    {
      title: `${t('Role')}`,
      dataIndex: 'role',
      width: '40px',
    },
    {
      title: `${t('Contact No')}`,
      dataIndex: 'contactNumber',
      width: '40px',
    },
    {
      title: `${t('Username')}`,
      dataIndex: 'userName',
      width: '40px',
    },
    {
      title: `${t('Status')}`,
      dataIndex: 'status',
      width: '30px',
      render: (status, item) => (
        <Tag
          style={{
            textAlign: 'center',
            alignSelf: 'center',
            borderRadius: '8px',
            width: 60,
            fontSize: 14,
            padding: 2,
            paddingLeft: 4,
            paddingRight: 4,
          }}
          color={item?.status === 'Active' ? '#27AE60' : '#EC0F0F'}
        >
          {item?.status === 'Active' ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: `${t('Action')}`,
      dataIndex: 'action',
      width: '30px',
      render: (action, item) => (
        <>
          <Popover placement="topLeft" content="View Item" trigger="hover">
            <Button
              type="text"
              icon={<img src="/assets/view.png" alt="View Item" />}
              onClick={() => triggerModal(ACTION_TYPE.VIEW, item)}
            />
          </Popover>
          {userRoles && !(userRoles.includes('Service-Advisor') || userRoles.includes('Technician')) && (
          <Popover placement="topLeft" content="Edit Item" trigger="hover">
            <Button
              type="text"
              icon={<img src="/assets/edit.png" alt="Edit Item" />}
              onClick={() => triggerModal(ACTION_TYPE.EDIT, item)}
            />
          </Popover>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <Title level={3} style={{ color: '#8C8C8C' }}>{t('Staff')}</Title>
        </Col>
      </Row>
      <Row style={{ paddingBottom: '20px' }}>
        <Col xs={14} sm={7} md={8} lg={14} xl={14} />
        <Col xs={3} sm={6} md={8} lg={3} xl={3} style={{ marginRight: '-30px', marginLeft: '30px' }}>
          <AddEmployee fetchData={fetchData} />
        </Col>
        <Col xs={6} sm={6} md={7} lg={6} xl={6} offset={1}>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder="Search"
            onChange={onSearch}
          />
        </Col>
      </Row>
      {!loading ? (
        <Loading />
      ) : (
        <Row className="table-row">
          <Table
            columns={columnsData}
            dataSource={staff}
            scroll={{ y: 500 }}
            pagination={{ onChange: tablePagination }}
          />
        </Row>
      )}

      <Drawer
        title={(
          <Row>
            <Col span={16}>
              {`${selectedItem?.name} (${selectedItem?.role})`}
            </Col>
            <Col span={7}>
              <Row style={{ float: 'right' }}>
                <Tag
                  style={{
                    textAlign: 'center',
                    alignSelf: 'center',
                    borderRadius: '8px',
                    fontSize: 14,
                    padding: 2,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                  color={selectedItem?.status === 'Active' ? '#27AE60' : '#EC0F0F'}
                >
                  {selectedItem?.status === 'Active' ? 'Active' : 'Inactive'}
                </Tag>
              </Row>
            </Col>
          </Row>
          )}
        width={window.innerWidth > 1001 ? 1000 : window.innerWidth - 2}
        placement="right"
        onClose={() => setModalVisible(false)}
        visible={selectedType === ACTION_TYPE.VIEW && isModalVisible}
      >
        <ViewEmployee employee={selectedItem} />
      </Drawer>
      <Drawer
        title="Edit Employee Details"
        placement="right"
        width={window.innerWidth > 1001 ? 1000 : window.innerWidth - 2}
        visible={selectedType === ACTION_TYPE.EDIT && isModalVisible}
        onClose={() => setModalVisible(false)}
      >
        <EditEmployee
          employee={selectedItem}
          updateStaff={fetchData}
          setVisible={setModalVisible}
        />
      </Drawer>
    </>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    userRoles: state.userData.userRoles,
  };
}

export default connect(mapStateToProps, {})(StaffPage);
