import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import {
  addJobs, deleteCheckIns,
  getAllCheckIn, getJobs,
  getAllCheckInUnauth,
} from '../../../graphql-queries/checkin';
import {
  APIS, getApi, deleteApi,
} from '../../../utils/apiUtils';
import { updateBookingData, updateSubscription } from '../../../graphql-queries/booking';
import { getWorkshop } from '../../../graphql-queries/workshop';
import { EMAIL_TYPE } from '../../../enum/ConfigurationData';

export const getCheckIn = async (workShopId: any, userId, date1, date2) => {
  try {
    return await API.graphql({ query: userId ? getAllCheckIn : getAllCheckInUnauth, variables: { workShopId: `${workShopId}`, date1: `${date1}`, date2: `${date2}` }, authMode: userId ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM' });
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getJobList = async (workShopId, bookingId) => {
  try {
    return await API.graphql(graphqlOperation(getJobs, { workShopId: `${workShopId}`, bookingId: `${bookingId}` }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const addJobList = async (checkInJobs) => {
  try {
    return await API.graphql(graphqlOperation(addJobs, { checkInJobs }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const updateBooking = async (updatedBooking: any) => {
  try {
    return await API.graphql(graphqlOperation(updateBookingData, { updatedBooking }));
  } catch (err: any) {
    message.destroy();
    console.log(err);
    message.error(err.message);
    return null;
  }
};

export const deleteJobList = async (deleteJobs) => {
  try {
    return await API.graphql(graphqlOperation(deleteCheckIns, { deleteJobs }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getSignedUrl = async (key, contentType) => {
  try {
    return await getApi(APIS.CHECK_IN_API, `check-in/signedurl?key=${key}&contentType=${contentType}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getImageUrl = async (key, contentType, method) => {
  try {
    return await getApi(APIS.CHECK_IN_API, `check-in/get-image-url?key=${key}&contentType=${contentType}&method=${method}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const deleteImage = async (key: String) => {
  try {
    return await deleteApi(APIS.CHECK_IN_API, `check-in/delete-image?key=${key}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const sendEmailNotification = async (bookingId: string) => {
  try {
    await getApi(APIS.EMAIL_API, `send-email?emailType=${EMAIL_TYPE.INVESTIGATION}&bookingId=${bookingId}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
  }
};

export const getWorkshopLogo = async (key, contentType, method) => {
  try {
    return await getApi(APIS.WORKSHOP_API, `workshop/get-image-url?key=${key}&contentType=${contentType}&method=${method}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const resolveTemplate = async (url: string) => {
  try {
    const res = await fetch(url);
    const template = await res.text();
    return template;
  } catch (e: any) {
    console.log(e);
    return null;
  }
};

export const getWorkshopData = async (workShopId: string) => {
  try {
    return await API.graphql(graphqlOperation(getWorkshop, { workShopId }));
  } catch (e: any) {
    console.log(e);
    return null;
  }
};

export const subscribeToLiveBooking = async (setNewBooking: Function) => {
  await API.graphql(graphqlOperation(updateSubscription)).subscribe({
    next: (data: any) => {
      const { onBookingApproveReject } = data.value.data;
      setNewBooking(onBookingApproveReject);
    },
    error: (err: any) => {
      console.log('subscription error', err);
    },
  });
};
