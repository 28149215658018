import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import {
  getBookings,
  getBookingsunauth,
  getJobs,
  searchEmployee,
  updateBookingData,
  updateComment,
  updateJobAssignee,
  updateJobStatus,
  updateQcComment,
} from '../../../graphql-queries/booking';
import { getInvoice, saveInvoice, updateInvoice } from '../../../graphql-queries/invoice';
import { getAllJobAssigneeQuery } from '../../../graphql-queries/employee';
import { APIS, getApi } from '../../../utils/apiUtils';
import { EMAIL_TYPE } from '../../../enum/ConfigurationData';

const updatedTime = new Date();

export const getAllBooking = async (workShopId, userId, date1, date2) => {
  try {
    return await API.graphql({ query: userId ? getBookings : getBookingsunauth, variables: { workShopId: `${workShopId}`, date1: `${date1}`, date2: `${date2}` }, authMode: userId ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM' });
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getJobList = async (workShopId, bookingId) => {
  try {
    return await API.graphql(graphqlOperation(getJobs, { workShopId: `${workShopId}`, bookingId: `${bookingId}` }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const updateBooking = async (updatedBooking: any) => {
  try {
    return await API.graphql(graphqlOperation(updateBookingData, { updatedBooking }));
  } catch (err: any) {
    console.log(err);
    message.destroy();
    message.error('Something went wrong when updating the booking').then();
    return null;
  }
};

export const updateJob = async (bookingId, sortKey, status) => {
  try {
    message.loading('Updating the job status').then();
    const res = await API.graphql(graphqlOperation(updateJobStatus, {
      bookingId: `${bookingId}`, sortKey: `${sortKey}`, status: `${status}`, updatedTime,
    }));
    message.destroy();
    message.success('Job status updated successfully');
    return res;
  } catch (err: any) {
    message.destroy();
    message.error('Something went wrong when updating job status');
    return null;
  }
};

export const getEmployeeDetails = async (workShopId, keyword) => {
  try {
    return await API.graphql(graphqlOperation(searchEmployee, { workShopId: `${workShopId}`, searching: `${keyword}` }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getAllJobAssignee = async (workShopId) => {
  try {
    return await API.graphql(graphqlOperation(getAllJobAssigneeQuery, { workShopId: `${workShopId}` }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const updateAssigneeJobs = async (bookingId, sortKey, assigneeId, assigneeName) => {
  try {
    return await API.graphql(graphqlOperation(updateJobAssignee, {
      bookingId, sortKey, assigneeId, assigneeName, updatedTime,
    }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const updateJobComment = async (bookingId, sortKey, comment) => {
  try {
    return await API.graphql(graphqlOperation(updateComment, {
      bookingId, sortKey, comment, updatedTime,
    }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const updateQCComments = async (bookingId, sortKey, qcComment) => {
  try {
    return await API.graphql(graphqlOperation(updateQcComment, {
      bookingId, sortKey, qcComment, updatedTime,
    }));
  } catch (err: any) {
    message.destroy();
    message.error('Error when updating QC comment');
    return null;
  }
};

export const addBookingInvoice = async (invoice) => {
  try {
    return await API.graphql(graphqlOperation(saveInvoice,
      { newInvoice: { ...invoice, createdTime: updatedTime, updatedTime } }));
  } catch (err: any) {
    message.destroy();
    message.error('Error when creating invoice');
    return null;
  }
};

export const getInvoiceDetails = async (invoiceId) => {
  try {
    return await API.graphql(graphqlOperation(getInvoice, { invoiceId }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const updateInvoiceDetails = async (invoice) => {
  try {
    return await API.graphql(graphqlOperation(updateInvoice, {
      updatedInvoice: {
        ...invoice,
        updatedTime,
      },
    }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const sendEmailNotification = async (bookingId: string) => {
  try {
    await getApi(APIS.EMAIL_API, `send-email?emailType=${EMAIL_TYPE.ONLINE_PAYMENT}&bookingId=${bookingId}`);
  } catch (err: any) {
    console.log(err);
    message.destroy();
    message.error('Error when sending the email notification');
  }
};

export const uploadBookingContent = async (key, method, contentType = undefined) => {
  try {
    const contentTypeP: string = contentType ? `&contentType=${contentType}` : '';
    return await getApi(APIS.BOOKING_API, `signed-url?key=${key}&method=${method}${contentTypeP}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};
