// eslint-disable-next-line import/prefer-default-export
export const TRANSLATIONS_TM = {
  'Make an Appointment': 'முன்பதிவுகள்',
  topic: 'முன்னேற்பாடு செய்',
  'Repair Items': 'பழுதுபார்க்கும் பொருள்',
  'Select an Item': 'ஒரு பொருளை தேர்ந்தெடுக்கவும்',
  'Unit..': 'அலகு..',
  'Type Description': 'விளக்கத்தை உள்ளிடவும்',
  'Please enter vehicle make': 'தயவுசெய்து உள்ளீடவும் தயாரிப்பாளர் நிறுவனம்',
  'Please enter insurance details': 'காப்பீட்டு விவரங்களை உள்ளிடவும்',
  'Please enter vehicle owner name': 'வாகனத்தின் உரிமையாளரின் பெயரை உள்ளிடவும்',
  'Please enter contact number with 10 digits': '10 இலக்கங்கள் கொண்ட தொடர்பு எண்ணை உள்ளிடவும்',
  'Please enter contact number': 'தொடர்பு எண்ணை உள்ளிடவும்',
  'Please enter a valid email address': 'சரியான மின்னஞ்சல் முகவரியை உள்ளிடவும்',
  'Please enter email': 'மின்னஞ்சலை உள்ளிடவும்',
  'Please enter vehicle model': 'வாகன மாதிரியை உள்ளிடவும்',
  'Please enter year of manufacture': 'தயாரிக்கப்பட்ட ஆண்டை உள்ளிடவும்',
  'Please enter year of register': 'பதிவின் ஆண்டை உள்ளிடவும்',
  'Please enter engine number': 'என்ஜின் எண்ணை உள்ளிடவும்',
  'Please enter chassis number': 'சேஸ் எண்ணை உள்ளிடவும்',
  'Please enter mileage': 'தயவுசெய்து மைலேஜை உள்ளிடவும்',
  'Enter Mileage': 'மைலேஜை உள்ளிடவும்',
  'Please enter color code': 'வண்ணக் குறியீட்டை உள்ளிடவும்',
  'Salary Details': 'சம்பள விவரங்கள்',
  'EPF Employee Contribution': 'EPF ஊழியர் பங்களிப்பு',
  'ETF Company Contribution': 'ETF நிறுவனத்தின் பங்களிப்பு',
  'EPF Company Contribution': 'EPF நிறுவனத்தின் பங்களிப்பு',
  'Please enter tier size': 'டயர் அளவை உள்ளிடவும்',
  'Please enter battery rate': 'பேட்டரி வீதத்தை உள்ளிடவும்',
  'Please enter fuel type': 'எரிபொருள் வகையை உள்ளிடவும்',
  'Please enter license details': 'உரிம விவரங்களை உள்ளிடவும்',
  'Plate Number': 'வாகன எண்',
  'Job Type': 'வேலை வகை',
  'Select a Job Type': 'வேலை வகையைத் தேர்ந்தெடுக்கவும்',
  'Vehicle Make': 'தயாரிப்பாளர் நிறுவனம்',
  'Enter Vehicle Make': 'தானியங்கு உருவாக்கத்தை உள்ளிடவும்',
  date: 'தேதி',
  time: 'நேரம்',
  SDate: 'ஒரு தேதியைத் தேர்ந்தெடுக்கவும்',
  Stime: 'நேரத்தைத் தேர்ந்தெடுங்கள்',
  'Add New Vehicle': 'புதிய வாகனத்தைச் சேர்க்கவும்',
  'More Information': 'மேலும் தகவல்',
  'Repair Item': 'பழுதுபார்க்கும் பொருள்',
  'Please select Job type': 'வேலை வகையைத் தேர்ந்தெடுக்கவும்',
  'Please select a Booking Date': 'முன்பதிவு தேதியைத் தேர்ந்தெடுக்கவும்',
  'Please select a Booking Time': 'முன்பதிவு நேரத்தைத் தேர்ந்தெடுக்கவும்',
  'Select time': 'ஒரு நேரத்தை தேர்வு செய்யவும்',
  Sitem: 'ஒரு நேரத்தை தேர்வு செய்யவும்',
  Description: 'விவரங்கள்',
  rate: 'மேலாண்மை விகிதம்',
  'Total Estimation': 'முழு மதிப்பீடு',
  'Type/Select a Description': 'விளக்கத்தை உள்ளிடவும்',
  'Standard Time': 'நிலையான நேரம்',
  'Vehicle Details': 'வாகன விவரங்கள்',
  'Select Year': 'ஆண்டைத் தேர்ந்தெடுக்கவும்',
  'Owner Details': 'உரிமையாளர் விவரங்கள்',
  'User Details': 'பயனர் விவரங்கள்',
  'Vehicle Number': 'வாகன எண்',
  'Vehicle Model': 'மாதிரி',
  'Year Of Manufacture': 'உற்பத்தி செய்த வருடம்',
  Mileage: 'மைலேஜ்',
  'Year of Register': 'பதிவு செய்யப்பட்ட ஆண்டு',
  enterManu_Year: 'உற்பத்தி ஆண்டை உள்ளிடவும்',
  'Engine Number': 'இயந்திர எண்',
  'Enter Engine Number': 'என்ஜின் எண்ணை உள்ளிடவும்',
  'Chassis Number': 'சேஸ் எண்',
  'Enter Chassis Number': 'சேஸ் எண்ணை உள்ளிடவும்',
  battery: 'பேட்டரி வகை / எண்',
  modelNo: 'மாடல் எண்',
  'Tyre Size': 'டயர் அளவு',
  'Enter Tyre Size': 'டயர் அளவை உள்ளிடவும்',
  'Owner Name': 'உரிமையாளரின் பெயர்',
  'Enter Owner Name': 'உரிமையாளரின் பெயர்',
  'Contact Number': 'தொடர்பு எண்',
  'Enter Contact Number': 'தொடர்பு எண்ணை உள்ளிடவும்',
  Email: 'மின்னஞ்சல்',
  'Enter Email': 'மின்னஞ்சலை உள்ளிடவும்',
  Save: 'சேமிக்க',
  Cancel: 'ரத்து செய்',
  home: 'முதல் பக்கம்',
  booking: 'முன்பதிவுகள்',
  'Job in progress': 'முன்பதிவுகளைப் பார்க்கவும்',
  createBooking: 'முன்னேற்பாடு செய்',
  inventory: 'சரக்கு',
  'Add Item': 'உருப்படியைச் சேர்க்கவும்',
  'Edit Item': 'திருத்த உருப்படி',
  'Goods Received': 'பொருட்கள் பெறப்பட்டன',
  'Download Template': ' பதிவிறக்க டெம்ப்ளேட்',
  admin: 'நிர்வாகம்',
  jobCreation: 'பொருட்கள் பெறப்பட்டன',
  regiNo: 'பதிவு எண்',
  'Enter Plate Number': 'வாகன எண்ணை உள்ளிடவும்',
  'Enter Vehicle Model': 'வாகன மாதிரியை உள்ளிடவும்',
  'Select Fuel Type': 'எரிபொருள் வகையைத் தேர்ந்தெடு',
  'Select Insurance Company': 'காப்பீட்டு நிறுவனத்தைத் தேர்ந்தெடுங்கள்',
  'Vehicle Registration Number': 'வாகனப் பதிவு எண்',
  'Battery Replacement Date': 'பேட்டரி மாற்று தேதி',
  'Enter Battery Replacement Date': 'பேட்டரி மாற்று தேதியை உள்ளிடவும்',
  'Color Code': 'வண்ண குறியீடு',
  'Enter Color Code': 'வண்ணக் குறியீட்டை உள்ளிடவும்',
  'Enter Battery Rate': 'பேட்டரி விகிதத்தை உள்ளிடவும்',
  'Fuel Type': 'எரிபொருள் வகை',
  'Enter Fuel Type': 'எரிபொருள் வகையை உள்ளிடவும்',
  'Enter License Number': 'உரிம எண்ணை உள்ளிடவும்',
  'License Details': 'உரிம விவரங்கள்',
  'Enter License Details': 'உரிம விவரங்களை உள்ளிடவும்',
  licenseNumber: 'உரிம எண்',
  'License Renewal Date': 'உரிமம் புதுப்பித்தல் தேதி',
  'Insurance Details': 'காப்பீட்டு விவரங்கள்',
  insuranceNumber: 'காப்பீட்டு எண்',
  'Insurance Renewal Date': 'காப்பீடு புதுப்பித்தல் தேதி',
  'Vehicle User Name': 'பயனர் விவரங்கள்',
  'Enter Insurance Details': 'காப்பீட்டு விவரங்களை உள்ளிடவும்',
  'Enter User Name': 'பயனர் பெயரை உள்ளிடவும்',
  submit: 'சமர்ப்பிக்க',
  logOut: 'வெளியேறு',
  stdHours: 'நிலையான நேரம்',
  Quantity: 'அளவு',
  Total: 'மொத்தம்:',
  price: 'விலை',
  // translations for global part search
  'Global Parts Search': 'உலகளாவிய பாகங்கள் தேடல்',
  // Job Creation page translations
  'Select File': 'கோப்பைத் தேர்ந்தெடுக்கவும்',
  'Edit Job Details': 'வேலை விவரங்களைத் திருத்தவும்',
  'Applicable Vehicle Make': 'பொருந்தக்கூடிய வாகன தயாரிப்பு',
  'Applicable Vehicle Model': 'பொருந்தக்கூடிய வாகன மாதிரி',
  'Checklist Have (1) / Don’t Have (0)': 'சரிபார்ப்புப் பட்டியல் உள்ளது (1) / இல்லை (0)',
  LH: 'LH',
  RH: 'RH',
  FRT: 'FRT',
  RER: 'RER',
  'Price Rs.': 'விலை ரூ.',
  'Labour Rate Type': 'தொழிலாளர் விகிதம் வகை',
  'Labour rate Rs.': 'தொழிலாளர் விகிதம் ரூ.',
  'STD Hrs (min)': 'STD மணி (நிமிடம்)',
  'Assistance Allowed (1) / Not Allowed (0)': 'உதவி அனுமதிக்கப்படுகிறது (1) /  அனுமதிக்கப்படவில்லை (0)',
  'Main Tech Incentive': 'முக்கிய தொழில்நுட்ப ஊக்கத்தொகை',
  'Assistant Tech Incentive (Rs.)': 'உதவி தொழில்நுட்ப ஊக்கத்தொகை (ரூ.)',
  'Job Category': 'வேலை பிரிவு',
  'Main Tech Group 1': 'முதன்மை தொழில்நுட்பக் குழு 1',
  'Main Tech Group 2': 'முதன்மை தொழில்நுட்பக் குழு 2',
  'Assistant Tech Group 2': 'உதவி தொழில்நுட்பக் குழு 2',
  'Active Status': 'செயலில் உள்ள நிலை',
  'Tax 1 (VAT %)': 'வரி 1 (VAT %)',
  'Tax 2 (NBT %)': 'வரி 2 (NBT %)',
  'Tax 3 (Other %)': 'வரி 3 (மற்ற %)',
  'Income Account Name': 'வருமான கணக்கு பெயர்',
  'P & L': 'பி & எல்',
  'INSTRUCTIONS / Notes': 'அறிவுறுத்தல்கள் / குறிப்புகள்',
  'Images (Hyper Link)': 'படங்கள் (ஹைப்பர் லிங்க்)',
  'Videos (HyperLink)': 'வீடியோக்கள் (ஹைப்பர்லிங்க்)',
  'Add Job Details': 'வேலை விவரங்களைச் சேர்க்கவும்',
  'Vehicle Category': 'வாகன வகை',
  'Enter Applicable Vehicle Make': 'பொருந்தக்கூடிய வாகன தயாரிப்பை உள்ளிடவும்',
  'Enter Applicable Vehicle Model': 'பொருந்தக்கூடிய வாகன மாதிரியை உள்ளிடவும்',
  'Enter Vehicle Category': 'வாகன வகையை உள்ளிடவும்',
  'Enter Check List Have (1) / Don’t Have (0)': 'சரிபார்ப்புப் பட்டியலை உள்ளிடவும் (1) / வேண்டாம் (0)',
  'Enter Description': 'விளக்கத்தை உள்ளிடவும்',
  'Enter LH': 'LH ஐ உள்ளிடவும்',
  'Enter RH': 'RH ஐ உள்ளிடவும்',
  'Enter FRT': 'FRT ஐ உள்ளிடவும்',
  'Enter RER': 'RER ஐ உள்ளிடவும்',
  'Enter Price': 'நிறுவன',
  'Select a person': 'ஒரு நபரைத் தேர்ந்தெடுக்கவும்',
  'Enter Rate': 'விகிதத்தை உள்ளிடவும்',
  'Enter Enter STD hours': 'STD நேரங்களை உள்ளிடவும்',
  'Enter Tax': 'உள்ளிடவும் TAX',
  'Enter Main Tech Incentive': 'முதன்மை தொழில்நுட்ப ஊக்கத்தொகையை உள்ளிடவும்',
  'Enter Assistant Tech Incentive': 'உதவி தொழில்நுட்ப ஊக்கத்தொகையை உள்ளிடவும்',
  'Enter Job Category': 'வேலை வகையை உள்ளிடவும்',
  'Enter Main Tech Group 1': 'முதன்மை தொழில்நுட்ப குழு 1 ஐ உள்ளிடவும்',
  'Enter Main Tech Group 2': 'முதன்மை தொழில்நுட்பக் குழு 2 ஐ உள்ளிடவும்',
  'Enter Assistant Tech Group 2': 'உதவி தொழில்நுட்பக் குழு 2ஐ உள்ளிடவும்',
  'Enter Active Status': 'செயலில் உள்ள நிலையை உள்ளிடவும்',
  'Enter Income Account Name': 'வருமானக் கணக்கின் பெயரை உள்ளிடவும்',
  'Enter P & L': 'P & L ஐ உள்ளிடவும்',
  'Enter INSTRUCTIONS / Notes': 'அறிவுறுத்தல்கள் / குறிப்புகளை உள்ளிடவும்',
  'Enter Images (Hyper Link)': 'படங்களை உள்ளிடவும் (ஹைப்பர் லிங்க்)',
  'Enter Videos (HyperLink)': 'வீடியோக்களை உள்ளிடவும் (ஹைப்பர்லிங்க்)',
  upload: 'பதிவேற்றவும்',
  'Please Enter Applicable Vehicle Make': 'பொருந்தக்கூடிய வாகனத் தயாரிப்பை உள்ளிடவும்',
  'Please Enter Applicable Vehicle Model': 'பொருந்தக்கூடிய வாகன மாதிரியை உள்ளிடவும்',
  'Please Enter Vehicle Category': 'வாகன வகையை உள்ளிடவும்',
  'Input should be a one digit number': 'உள்ளீடு ஒரு இலக்க எண்ணாக இருக்க வேண்டும்',
  'Please Enter Description': ' தயவுசெய்து விளக்கத்தை உள்ளிடவும்',
  'Please Enter LH': 'தயவுசெய்து LH ஐ உள்ளிடவும்',
  'Please Enter RH': 'தயவுசெய்து RH ஐ உள்ளிடவும்',
  'Please Enter FRT': 'தயவுசெய்து FRT ஐ உள்ளிடவும்',
  'Please Enter RER': 'தயவுசெய்து RER ஐ உள்ளிடவும்',
  'Please Enter Price': 'தயவுசெய்து விலையை உள்ளிடவும்',
  'Please Enter Labour Rate Type': 'தொழிலாளர் விகித வகையை உள்ளிடவும்',
  'Please Enter Rate': 'தயவுசெய்து விகிதத்தை உள்ளிடவும்',
  'Please Enter Enter STD hours': 'STD நேரங்களை உள்ளிடவும்',
  'Input should be a one digit number ': 'உள்ளீடு ஒரு இலக்க எண்ணாக இருக்க     வேண்டும் ',
  'Please Enter Main Tech Incentive': 'முதன்மை தொழில்நுட்ப ஊக்கத்தொகையை உள்ளிடவும்',
  'Please Enter Assistant Tech Incentive': 'உதவி தொழில்நுட்ப ஊக்கத்தொகையை  உள்ளிடவும்',
  'Please Enter Job Category': 'வேலை வகையை உள்ளிடவும்',
  'Please Enter Main Tech Group 1': 'முதன்மை தொழில்நுட்பக் குழு 1ஐ உள்ளிடவும்',
  'Please Enter Main Tech Group 2': 'முதன்மை தொழில்நுட்பக் குழு 2ஐ உள்ளிடவும்',
  'Please Enter Assistant Tech Group 2': 'உதவி தொழில்நுட்பக் குழு 2ஐ உள்ளிடவும்',
  'Please Enter Active Status': 'செயலில் உள்ள நிலையை உள்ளிடவும்',
  'Please Enter Tax': 'வரியை உள்ளிடவும்',
  'Please Enter Income Account Name': 'வருமானக் கணக்கின் பெயரை உள்ளிடவும்',
  'Please Enter P & L': 'P & L ஐ உள்ளிடவும்',
  'Please Enter INSTRUCTIONS / Notes': 'அறிவுறுத்தல்கள் / குறிப்புகளை உள்ளிடவும்',
  'Please Enter Images (Hyper Link)': 'அறிவுறுத்தல்கள் / குறிப்புகளை உள்ளிடவும்',
  jobName: 'பணியின் பெயர்',
  'Input should be a number ': 'உள்ளீடு ஒரு எண்ணாக இருக்க வேண்டும் ',
  'Please Enter Checklist Have (1) / Don’t Have (0)': 'சரிபார்ப்புப் பட்டியலை உள்ளிடவும் (1) / வேண்டாம் (0)',
  subSection: 'துணைப் பிரிவு',
  sectionDescription: 'ஒவ்வொரு வேலை விவரத்தையும் ஒரு நேரத்தில் ஒரு வேலையைப் பற்றி விவரிக்கவும்',
  qty: 'அளவு அல்லது மணிநேரம்',
  addJob: 'வேலையைச் சேர்க்கவும்',
  jobList: 'வேலை பட்டியல்',
  'Please enter a valid number': 'சரியான எண்ணை உள்ளிடவும்',
  Edit: 'தொகு',
  'Upload File': 'கோப்பை பதிவேற்றவும்',
  // validation messages
  regiNoMessage: 'வாகன எண்ணை உள்ளிடவும்',
  jobTypeMessage: 'வேலை வகையைத் தேர்ந்தெடுக்கவும்',
  bookingDateMessage: 'முன்பதிவு தேதியைத் தேர்ந்தெடுக்கவும்',
  bookingTimeMessage: 'முன்பதிவு நேரத்தைத் தேர்ந்தெடுக்கவும்',
  selectItemMessage: 'தயவுசெய்து ஒரு பொருளைத் தேர்ந்தெடுக்கவும்',
  inputRateMessage: 'உள்ளீடு விகிதம்',
  qtyHoursMessae: 'தயவுசெய்து qty_Hours ஐ உள்ளிடவும்',

  /* tour messages */
  'Skip Button': 'தவிர்க்கவும்',
  // dashboard tour
  heyThere: 'வணக்கம்...!',
  welcomeMessage: 'உங்கள் பணியிடத்திற்கு வரவேற்கிறோம்.',
  areYouReady: 'உங்கள் பணியிடத்தைப் பார்வையிட நீங்கள் தயாரா?',
  selectLanguageHere: 'நீங்கள் விரும்பும் மொழியை இங்கே தேர்ந்தெடுக்கவும்..',
  viewNotification: 'தகவல் பார்க்கவும்',
  viewNotificationHere: 'உங்களுக்கு இதிலிருந்து அறிவிப்பு அனுப்பியிருக்கலாம்',
  youAreonDashboard: 'நீங்கள் வேலைபிம் இணையதளத்தில் உபகரணங்கள் கருவி உள்ளது!',
  // create booking tour
  areYouReadyToCreateBooking: 'நீங்கள் விரும்புவதற்கு உருவாக்க தயார் ?',
  makeAnAppointment: 'செய்ய வேண்டும்',
  selectaDate: 'Select a date from the calendar view ',
  fillTheFromToMakeAnAppointment: 'Fill the form to make an appointment',
  addNewVehicle: 'புதிய வாகனத்தைச் சேர்க்கவும்',
  clickHereToAddNewVehicle: 'Click here to add a new vehicle, if the vehicle is not registerd in Karstation',
  searchVehicle: 'வாகனத்தைத் தேடுங்கள்',
  searchVehicleFromHere: 'If the vehicle is already registered, search vehicle with plate number',
  // dashboard
  dashboard: 'டாஷ்போர்டு',
  // view task
  Jobtype: ' வேலை வகை',
  'Booking Details': ' முன்பதிவு விவரங்கள்',
  Platenumber: ' தகடு எண்',
  VehicleMake: ' வாகனம் தயாரித்தல்',
  Vehiclemodel: ' வாகன மாதிரி',
  Year: ' ஆண்டு',
  Enginenumber: ' எஞ்சின் எண்',
  Chassisnumber: ' சேஸ் எண்',
  Colorcode: ' வண்ண குறியீடு',
  Tyresize: ' டயர் அளவு',
  Batteryrate: ' பேட்டரி வீதம்',
  Fueltype: ' எரிபொருள் வகை',
  Oname: ' பெயர்',
  Ocontactnumber: ' தொடர்பு எண்',
  Oemail: ' மின்னஞ்சல் கணக்கு',
  Uname: ' பெயர்',
  Ucontactnumber: ' தொடர்பு எண்',
  Uemail: ' மின்னஞ்சல் கணக்கு',
  Standardhoursmin: ' Standard Hours(min)',
  Qty: ' அளவு',
  Rate: ' மதிப்பிடவும்',
  Price: ' விலை',
  Status: ' நிலைமை',
  Assignee: ' ஒதுக்கப்பட்டவர்',
  Comment: ' கருத்து',
  Qcstatus: ' QC Status',
  Bookinghistory: ' முன்பதிவு வரலாறு',
  // view task investigation page
  MakeaCheckIn: ' செக்-இன் செய்யுங்கள்',

  // create booking tour
  'Are you ready to create a Booking ?': 'முன்பதிவை உருவாக்க நீங்கள் தயாரா?',
  'Make an appointment': 'முன்னேற்பாடு செய்',
  'Select a date from the calendar view': 'காலண்டர் பார்வையில் இருந்து ஒரு தேதியைத் தேர்ந்தெடுக்கவும்',
  'Fill the form to make an appointment': 'சந்திப்பைச் செய்ய படிவத்தை நிரப்பவும்',
  'Add new Vehicle': 'புதிய வாகனத்தைச் சேர்க்கவும்',
  'Click here to add a new vehicle, if the vehicle is not registerd in Karstation': 'கார்ஸ்டேஷனில் பதிவு செய்யப்படாத வாகனம், புதிய வாகனத்தைச் சேர்க்க இங்கே கிளிக் செய்யவும்',
  'Search vehicle': 'வாகனத்தைத் தேடுங்கள்',
  'If the vehicle is already registered, search vehicle with plate number': 'வாகனம் ஏற்கனவே பதிவு செய்யப்பட்டிருந்தால், தட்டு எண்ணுடன் வாகனத்தைத் தேடுங்கள்',
  // dashboard
  // view task
  'Battery Rate': 'பேட்டரி ரேட்',
  Name: 'பெயர்',
  'Booking History': 'முன்பதிவு வரலாறு',
  // view task investigation
  'Make a Check-In': 'செக்-இன் செய்யுங்கள்',
  Images: 'படங்கள்',
  // chech in page
  Investigation: 'விசாரணை',
  'Add Comments': 'கருத்துகளைச் சேர்க்கவும்',
  'Generate Report': 'அறிக்கையை உருவாக்கவும்',
  Approved: 'அங்கீகரிக்கப்பட்டது',
  Comments: 'கருத்துகள்',
  'Submit to Customer': 'வாடிக்கையாளரிடம் சமர்ப்பிக்கவும்',
  'Resend Email': 'மின்னஞ்சலை மீண்டும் அனுப்பவும்',
  // inventory page
  Category: 'வகை',
  PriceRS: ' விலை',
  'Part Number': 'பகுதி எண்',
  'Part Name': 'பகுதி பெயர்',
  'Other Names': 'மற்ற பெயர்கள்',
  'Description / Details': 'விளக்கம் / விவரங்கள்',
  'Warehouse Type': 'கிடங்கு வகை',
  'Bin Location': 'தொட்டி இடம்',
  'Anticipated Usage': 'எதிர்பார்க்கப்படும் பயன்பாடு',
  Action: 'செயல்',
  'Item Type': 'பொருள் வகை',
  'Supplier Information': 'சப்ளையர் தகவல்',
  'Goods In': 'பொருட்கள் உள்ளே',
  'Goods Out': 'பொருட்கள் தீர்ந்தன',
  'Warranty Details': 'உத்தரவாத விவரங்கள்',
  'Browse Image (Optional)': 'படத்தை உலாவவும் (விரும்பினால்)',
  'Min Order Quantity To Supplier': 'சப்ளையருக்கு குறைந்தபட்ச ஆர்டர் அளவு',
  'Select Item Type': 'உருப்படி வகையைத் தேர்ந்தெடுக்கவும்',
  'Enter Part Number': 'பகுதி எண்ணை உள்ளிடவும்',
  'Enter Part Name': 'பகுதி பெயரை உள்ளிடவும்',
  'Enter Supplier Information': 'சப்ளையர் தகவலை உள்ளிடவும்',
  'Enter Other Names': 'மற்ற பெயர்களை உள்ளிடவும்',
  'Enter Warehouse Type': 'கிடங்கு வகையை உள்ளிடவும்',
  'Enter Bin Location': 'தொட்டி இருப்பிடத்தை உள்ளிடவும்',
  'Enter Anticipated Usage': 'எதிர்பார்க்கப்படும் பயன்பாட்டை உள்ளிடவும்',
  'Enter Warranty Details': 'உத்தரவாத விவரங்களை உள்ளிடவும்',
  'Enter Min Order Quantity-To-Supplier': 'சப்ளையருக்கு குறைந்தபட்ச ஆர்டர் அளவை உள்ளிடவும்',
  'Browse Image': 'படத்தை உலாவவும்',
  'Labour Rate': 'தொழிலாளர் விகிதம்',
  'Checklist Availability': 'சரிபார்ப்பு பட்டியல் கிடைக்கும்',
  // CheckListAvailabilit: ' பட்டியல் கிடைப்பதை சரிபார்க்கவும்',
  'Assistance Allowed': 'உதவி அனுமதிக்கப்படுகிறது',
  'Assistant Tech Incentive': 'உதவி தொழில்நுட்ப ஊக்கத்தொகை',
  'Tax 2 (NBT 2)': 'வரி 2 (NBT 2)',
  'Tax 3 (Other)': 'வரி 3 (மற்றவை)',
  'Videos (Hyper Link)': 'வீடியோக்கள் (ஹைப்பர் லிங்க்)',
  Inventory: ' சரக்கு',
  Jobs: ' வேலைகள்',
  // staff page
  EmployeeID: 'பணியாளர் ஐடி',
  Next: 'அடுத்தது',
  Back: 'மீண்டும்',
  'Date of Birth': 'பிறந்த தேதி',
  Address: 'முகவரி',
  'Employee Name': 'பணியாளர் பெயர்',
  'Enter Account Holder Name': 'கணக்கு வைத்திருப்பவரின் பெயரை உள்ளிடவும்',
  'Enter Employee Bank': 'பணியாளர் வங்கியை உள்ளிடவும்',
  'Enter The Branch': 'கிளையை உள்ளிடவும்',
  'Enter Account Number': 'கணக்கு எண்ணை உள்ளிடவும்',
  'Enter Basic Salary': 'அடிப்படை சம்பளத்தை உள்ளிடவும்',
  'Enter EPF': 'EPF ஐ உள்ளிடவும்',
  'Enter ETF': 'ETF ஐ உள்ளிடவும்',
  'Please Enter Account Holder Name': 'கணக்கு வைத்திருப்பவரின் பெயரை உள்ளிடவும்',
  'Please Enter Employee Bank': 'பணியாளர் வங்கியை உள்ளிடவும்',
  'Please Enter Branch': 'கிளையை உள்ளிடவும்',
  'Please Enter Account Number': 'கணக்கு எண்ணை உள்ளிடவும்',
  'Please Enter Basic Salary': 'அடிப்படை சம்பளத்தை உள்ளிடவும்',
  'Enter Employee Address': 'பணியாளர் முகவரியை உள்ளிடவும்',
  Role: ' பங்கு',
  'Please Enter EPF': 'தயவுசெய்து EPF ஐ உள்ளிடவும்',
  'Please Enter ETF': 'ETFஐ உள்ளிடவும்',
  'G.C.E. O/L Certificate  [NVQ4]': 'ஜி.சி.இ. O/L சான்றிதழ் [NVQ4]',
  'G.C.E A/L Certificate  [NVQ5]': 'G.C.E A/L சான்றிதழ் [NVQ5]',
  'NDT/HNDE/Diploma in Automobile Engineering  [NVQ 6]': 'NDT/HNDE/ஆட்டோமொபைல் இன்ஜினியரிங் டிப்ளமோ [NVQ 6]',
  'BSc (Engineering)/BEng Automobile [NVQ 7]': 'BSc (பொறியியல்)/BEng ஆட்டோமொபைல் [NVQ 7]',
  'Msc Engineering/ MEng Automobile': 'Msc பொறியியல்/ MEng ஆட்டோமொபைல்',
  'Other Professional  Registrations': 'பிற தொழில்முறை பதிவுகள்',
  'Incorporated Engineer Registration': 'ஒருங்கிணைந்த பொறியாளர் பதிவு',
  'Charted Engineer Registration': 'பட்டயப் பொறியாளர் பதிவு',
  Browse: 'உலாவவும்',
  'Please Enter Employee Name': 'பணியாளரின் பெயரை உள்ளிடவும்',
  'Please Select Employee Role': 'பணியாளர் பங்கைத் தேர்ந்தெடுக்கவும்',
  'Please Enter Employee NIC Number': 'தயவுசெய்து பணியாளர் NIC எண்ணை உள்ளிடவும்',
  'Please Enter Employee Contact Number': 'பணியாளர் தொடர்பு எண்ணை உள்ளிடவும்',
  'Please Enter User Name': 'பயனர் பெயரை உள்ளிடவும்',
  'Please Select Status': 'தயவுசெய்து நிலையைத் தேர்ந்தெடுக்கவும்',
  'Contact No': 'தொடர்பு எண்',
  'Enter Employee Name': 'பணியாளரின் பெயரை உள்ளிடவும்',
  'Select Employee Role': 'பணியாளர் பங்கைத் தேர்ந்தெடுக்கவும்',
  'Enter NIC Number': 'NIC எண்ணை உள்ளிடவும்',
  'Select Date of Birth': 'பிறந்த தேதியைத் தேர்ந்தெடுக்கவும்',
  Username: 'பயனர் பெயர்',
  'Please Enter Date of Birth': 'பிறந்த தேதியை உள்ளிடவும்',
  Staff: ' பணியாளர்கள்',
  // workshops
  AddNewWorkshops: ' புதிய பட்டறையைச் சேர்க்கவும்',
  Workshops: ' பட்டறைகள்',
  WorkshopID: ' பணிமனை ID',
  WorkshopName: ' பட்டறை பெயர்',
  WorkshopRegistrationNumber: ' பட்டறை பதிவு எண்',
  WorkshopContactDetails: ' பட்டறை தொடர்பு விவரங்கள்',
  // main page
  History: ' வரலாறு',
  GlobalParts: ' உலகளாவிய பாகங்கள்',
  'Add an Employee': 'ஒரு பணியாளரைச் சேர்க்கவும்',
  EmployeeName: ' பணியாளர் பெயர்',
  'Employee Role': 'பணியாளர் பங்கு',
  'NIC Number': 'NIC எண்',
  'Date of Birth (Optional)': 'பிறந்த தேதி (விரும்பினால்)',
  'Address (Optional)': 'முகவரி (விரும்பினால்)',
  'User Name': 'பயனர் பெயர்',
  General: ' பொது',
  BrowseImage: ' படத்தை உலாவுக (விரும்பினால்)',
  Salary: ' சம்பளம்',
  'Account Holder Name': 'கணக்கு வைத்திருப்பவரின் பெயர்',
  'Employee Bank': 'பணியாளர் வங்கி',
  Branch: 'கிளை',
  'Account Number': 'கணக்கு எண்',
  'Basic Salary': 'அடிப்படை சம்பளம்',
  Incentives: 'ஊக்கத்தொகை',
  Qualifications: ' தகுதிகள்',
  'Configuration Data': 'கட்டமைப்பு தரவு',
  'Job Types': 'வேலை வகைகள்',
  Password: ' கடவுச்சொல்',
  Bank: 'வங்கி',
  Currency: 'நாணய',
  'By configuring data you will be able to add ‘Job Types ’ and ‘Repair items’ to the system.': 'By configuring data you will be able to add ‘Job Types ’ and ‘Repair items’ to the system.',

  AddanEmployee: ' ஒரு பணியாளரைச் சேர்க்கவும்',
  View: 'காண்க',
  'Passed with conditions': 'நிபந்தனைகளுடன் நிறைவேற்றப்பட்டது',
  Rejected: 'நிராகரிக்கப்பட்டது',
  Search: 'தேடல்',
  From: 'இருந்து',
  To: 'செய்ய',
  'JOBS IN QUEUE': 'வரிசையில் வேலைகள்',
  'IN PROGRESS': 'செயல்பாட்டில் உள்ளது',
  'QUALITY CONTROL': 'தர கட்டுப்பாடு',
  PAYMENTS: 'கொடுப்பனவுகள்',
  'Jobs In Queue': 'வரிசையில் வேலைகள்',
  'In Progress': 'செயல்பாட்டில் உள்ளது',
  'Quality Control': 'தர கட்டுப்பாடு',
  'Pending Payment': 'நிலுவையில் உள்ள கொடுப்பனவுகள்',
  Accident: 'விபத்து',
  Repair: 'பழுது',
  Breakdown: 'முறிவு',
  Service: 'சேவை',
  'Standard Hours(min)': 'வழக்கமான நேரம்(நிமிடம்)',
  Start: 'தொடங்கு',
  Pause: 'இடைநிறுத்தம்',
  Done: 'முடிந்தது',
  'Select the Reason': 'காரணத்தைத் தேர்ந்தெடுக்கவும்',
  Pay: 'முறிவு',
  Cash: 'பணம்',
  Online: 'நிகழ்நிலை',
  'QC Status': 'QC நிலை',
  Pass: 'பாஸ்',
  Fail: 'தோல்வி',
  'Assign to..': 'ஒதுக்க..',
  'Submit to Pending Payment': 'நிலுவையில் உள்ள கட்டணத்திற்குச் சமர்ப்பிக்கவும்',
  'Submit to In-Progress': 'செயல்பாட்டில் சமர்ப்பிக்கவும்',
  'Submit to QC': 'QC க்கு சமர்ப்பிக்கவும்',
  Invoice: 'இன்வொய்சிய',
  'All jobs need to be completed to proceed': 'மேலே செல்ல அனைத்து முழுமையாக செய்ய வேண்டும்',
  'To proceed there should be at least one job started': 'தொடர குறைந்தபட்சம் ஒரு பணியையாவது தொடங்க வேண்டும்',
  'To continue, all jobs should be QC passed or use the passed with conditions option': 'தொடர, அனைத்து வேலைகளும் QC தேர்ச்சி பெற்றிருக்க வேண்டும் அல்லது நிபந்தனைகளுடன் தேர்ச்சி விருப்பத்தைப் பயன்படுத்த வேண்டும்',
  'To Do': 'வரிசையில் வேலைகள்',
  Quickjobs: 'விரைவான வேலைகள்',
  'Export CSV': 'ஏற்றுமதி. CSV',
};
