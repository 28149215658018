import React, { useEffect, useState } from 'react';
import {
  Button, Col, message, Modal, Radio, Row, Space, Table, Typography, Upload,
} from 'antd';
import Handlebars from 'handlebars';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import printJS from 'print-js';
import html2pdf from 'html2pdf.js';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import { USER_ROLES } from '../../../../../enum/User';
import { BOOKING_TYPES, PAYMENT_TYPE } from '../../../../../enum/BookingStatus';
import * as payment from '../../../../../animations/payment.json';
import { JOB_STATUS } from '../../../../../enum/JobStatus';
import {
  getInvoiceDetails,
  sendEmailNotification,
  updateBooking,
  updateInvoiceDetails,
  uploadBookingContent,
} from '../../actions';
import { getWorkshopData, getWorkshopLogo, resolveTemplate } from '../../../investigation/action';
import { TaskBoardItemStatus } from '../../../../../enum/TaskboardTypes';
import { getTotalPrice } from '../common';
import { formatNumber, getFormattedJobs } from '../../../../../utils/tableUtil';
import PendingPaymentColumn from '../columns/PendingPaymentColumn';
import { JOB_TYPES, S3_METHODS } from '../../../../../enum/ConfigurationData';
import { hasRole } from '../../../../../utils/commonUtil';

type PendingPaymentType = {
  userRoles: any[],
  item: any,
  jobList: any[],
  workshop: any,
  workShopId: string
  getAllBookings: Function,
  configData: any
};

const PendingPayment = ({
  userRoles,
  item,
  jobList,
  workshop,
  workShopId,
  getAllBookings,
  configData,
}: PendingPaymentType) => {
  const { default: animationData }: any = payment;
  const { t } = useTranslation();
  const { Title } = Typography;
  const [invoice, setInvoice] = useState<any>();
  const [paymentType, setPaymentType] = useState<string>();
  const [report, setReport] = useState<any>();
  const [extendedReport, setExtendedReport] = useState<any>();
  const [isReportModalVisible, setReportModalVisible] = useState<boolean>(false);
  const [formattedJobList, setFormattedJobList] = useState<any[]>([]);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const { currency = '', languageCode = '' }: any = configData?.currency;
  const [fileList, setFileList] = useState<any[]>([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const isQuickJob = JOB_TYPES[JOB_TYPES.QuickJobs].toString().toLowerCase();
  const isQuickBooking = item.jobType === isQuickJob;

  const dataColumn = PendingPaymentColumn(t, isQuickBooking);

  const getInvoice = () => {
    if (item.status === TaskBoardItemStatus.PENDING_PAYMENT && item.invoiceId) {
      getInvoiceDetails(item.invoiceId).then((res: any) => {
        setInvoice(res.data.getInvoice);
      }).catch(() => {
        message.error('Something went wrong when getting the invoice details').then();
      });
    }
  };

  const toggleReportModal = () => {
    setReportModalVisible(!isReportModalVisible);
    if (isReportModalVisible) getAllBookings();
  };

  const printReport = async () => {
    const element = document.getElementById('report-view');
    const opt = {
      jsPDF: {
        format: 'A4',
        orientation: 'portrait',
      },
      html2canvas: {
        scale: 2,
        dpi: 300,
        letterRendering: true,
        useCORS: true,
      },
      image: { type: 'png' },

    };
    const res = await html2pdf()
      .set(opt)
      .from(element)
      .outputImg();

    printJS({
      printable: res.src,
      type: 'image',
      properties: ['prop1', 'prop2', 'prop3'],
    });
  };

  const downloadReport = async () => {
    const element = document.getElementById('report-view');
    const opt = {
      filename: 'invoice.pdf',
      html2canvas: {
        scale: 2,
        dpi: 300,
        letterRendering: true,
        useCORS: true,
      },
      jsPDF: {
        format: 'A4',
        orientation: 'portrait',
      },
      image: { type: 'png' },
    };
    html2pdf(element, opt);
  };

  const generateInvoice = () => {
    message.loading('Generating Invoice').then();
    resolveTemplate('/reports/investigation/report-en.html').then(async (temp: string | null) => {
      const template = Handlebars.compile(temp);
      const tempList = jobList.filter((tDItem) => tDItem.status !== JOB_STATUS.REJECTED);
      const totalEstimation: any[] = getFormattedJobs(tempList, currency, languageCode);
      const customLogo = workshop.logo && await getWorkshopLogo(workshop.logo, 'image/png', 'getObject');
      const workshopData: any = await getWorkshopData(workShopId);
      const {
        workshopName, address, contactNumber,
      } = workshopData.data.getWorkshop;
      // Pass only 8 jobs(including titles) to estimation table in report-view page 1
      const estimationTableData = totalEstimation.slice(0, 8);
      let totalPrice;
      if (totalEstimation.length < 8) {
        totalPrice = formatNumber(getTotalPrice(jobList), currency, languageCode);
      } else {
        totalPrice = null;
      }
      const data = {
        ...item,
        totalEstimation: estimationTableData,
        customLogo: customLogo.url || '/assets/logo.png',
        total: totalPrice,
        workshopName,
        address,
        contactNumber,
      };
      const result: any = template(data);
      setTimeout(() => {
        setReport(result);
        toggleReportModal();
      }, 1000);
    });

    resolveTemplate('/reports/investigation/report-extended-en.html').then(async (temp) => {
      const template = Handlebars.compile(temp);
      const tempList = jobList.filter((tDItem) => tDItem.status !== JOB_STATUS.REJECTED);
      const totalEstimation: any[] = getFormattedJobs(tempList, currency, languageCode);
      // Pass rest of jobs(excluding titles) to estimation table in report-view page 2
      const estimationTableData = totalEstimation.slice(8);
      let totalPrice;
      if (totalEstimation.length > 7) {
        totalPrice = formatNumber(getTotalPrice(jobList), currency, languageCode);
      } else {
        totalPrice = null;
      }
      const data = {
        totalEstimation: estimationTableData,
        total: totalPrice,
      };
      const result = template(data);
      setExtendedReport(result);
      message.destroy();
      message.success('Invoice generated successfully').then();
    });
  };

  const updateInvoice = (updatedInvoice) => {
    updateInvoiceDetails(updatedInvoice).then(() => {
      message.success('Invoice successfully updated').then();
    }).catch(() => {
      message.destroy();
      message.error('Something went wrong, when updating the invoice').then();
    });
  };

  const toggleConfirmModal = () => {
    setConfirmVisible(!confirmVisible);
  };

  const paymentOption = (e) => {
    e.preventDefault();
    setPaymentType(e.target.value);
  };

  const uploadCreditAttachments = async () => {
    message.loading('Uploading credit attachment..').then();
    const promises = fileList.map(async (file: any) => {
      const key = `${workShopId}/${item.sortKey}/Credit/${file.name}`;
      const contentType = file.type;
      const { body } = await uploadBookingContent(key, S3_METHODS.put, contentType);
      if (body?.url) {
        await fetch(body.url, {
          method: 'PUT',
          headers: {
            'Content-Type': contentType,
          },
          body: file.originFileObj,
        });
        return file.name;
      }
      throw new Error('Something went wrong');
    });
    const [image] = await Promise.all(promises);
    message.success('Credit attachment uploaded successfully').then();
    return image;
  };

  const submitPendingPayment = async () => {
    const totalPrice = getTotalPrice(jobList);
    const updatedBooking: any = {
      ...item,
      workShopId,
      status: JOB_STATUS.DONE,
      updatedTime: new Date(),
      totalPrice,
      paymentMethod: paymentType,
    };

    if (paymentType === PAYMENT_TYPE.CASH) {
      await updateBooking(updatedBooking);
    } if (paymentType === PAYMENT_TYPE.CREDIT) {
      if (!fileList.length) {
        message.error('Insurance company letter required');
        return null;
      }
      updatedBooking.creditAttachment = await uploadCreditAttachments();
      updatedBooking.status = BOOKING_TYPES.AWAITING_APPROVAL;
      await updateBooking(updatedBooking);
    } else {
      await sendEmailNotification(updatedBooking.sortKey);
    }
    generateInvoice();
    if (invoice) {
      const inv: any = invoice;
      inv.paymentMethod = paymentType;
      if (paymentType === PAYMENT_TYPE.CASH) inv.status = JOB_STATUS.DONE;
      if (paymentType === PAYMENT_TYPE.CREDIT) inv.status = BOOKING_TYPES.AWAITING_APPROVAL;
      updateInvoice(inv);
    }
    toggleConfirmModal();
    return null;
  };

  const onImageChange = (info) => {
    const { fileList: newFileList, file } = info;
    setFileList(newFileList);
    if (file.status === 'done') {
      message.destroy();
      message.success('Updated successfully');
    }
  };

  const onImageRemove = async (image) => {
    const imgArray: any[] = item.images || [];
    if (imgArray.includes(image.name)) {
      const tempArray = [];
      fileList.map((img: any) => {
        if (img.name !== image.name) {
          // @ts-ignore
          tempArray.push(img.name);
        }
        return null;
      });
    }
    message.success('File deleted successfully');
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  useEffect(() => {
    if (jobList.length) {
      setFormattedJobList(getFormattedJobs(jobList, currency, languageCode));
    }
  }, [jobList]);

  useEffect(() => {
    getInvoice();
  }, []);

  return (
    <>
      <Row>
        <header className="tableTopic">{t('Invoice')}</header>
      </Row>
      <Row className="tab-row">
        <Col span={24}>
          <Table
            columns={dataColumn}
            dataSource={formattedJobList}
            pagination={false}
          />
        </Col>
      </Row>
      <Row style={{ float: 'right', marginTop: '1rem' }}>
        <Col>
          <Button
            htmlType="submit"
            style={{
              borderRadius: '8px',
              marginRight: 5,
              background: '#131a54',
              color: 'white',
              width: '100px',
            }}
            onClick={toggleConfirmModal}
            disabled={hasRole([USER_ROLES.TECHNICIAN, USER_ROLES.SERVICE_ADVISOR], userRoles)}
          >
            {t('Pay')}
          </Button>
        </Col>
      </Row>
      <Modal
        title={<Title level={5}>Are you sure you want to make this payment?</Title>}
        visible={confirmVisible}
        onOk={submitPendingPayment}
        onCancel={toggleConfirmModal}
        okText={paymentType === PAYMENT_TYPE.CREDIT ? t('Submit') : t('Check out')}
        style={{ marginTop: 100 }}
        width={600}
      >
        <Row>
          <Col span={15}>
            <Lottie
              options={defaultOptions}
              height={160}
              width={200}
            />
          </Col>
          <Col>
            <Radio.Group
              style={{ marginTop: 25, marginLeft: 20 }}
              onChange={paymentOption}
              value={paymentType}
            >
              <Space direction="vertical">
                <Radio value={PAYMENT_TYPE.CASH}>{t('Cash')}</Radio>
                <Radio disabled value={PAYMENT_TYPE.ONLINE}>{t('Online')}</Radio>
                <Radio value={PAYMENT_TYPE.CREDIT}>
                  {t('Credit')}
                  {paymentType === PAYMENT_TYPE.CREDIT
                    && (
                    <>
                      <Row className="tab-row">
                        <Col>
                          <Row>
                            <Col className="col-title" style={{ width: '125px' }}>{t('Attachments')}</Col>
                            <Col className="col-value" style={{ width: '375px' }}>
                              <Upload
                                fileList={fileList}
                                onChange={onImageChange}
                                customRequest={dummyRequest}
                                onRemove={onImageRemove}
                                accept=".pdf"
                                maxCount={1}
                                disabled={
                                hasRole(
                                  [USER_ROLES.TECHNICIAN, USER_ROLES.SERVICE_ADVISOR],
                                  userRoles,
                                )
                              }
                              >
                                {fileList.length < 1
                                && <Button type="link" style={{ marginTop: '-5px', marginBottom: '-5px' }} icon={<UploadOutlined />}>{t('Browse')}</Button>}
                              </Upload>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                    )}
                </Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      </Modal>
      <Modal
        width={1000}
        title="Invoice"
        visible={isReportModalVisible}
        onCancel={toggleReportModal}
        footer={[
          <Button key="1" type="text" style={{ backgroundColor: 'white' }} onClick={printReport}>Print</Button>,
          <Button key="2" type="text" style={{ backgroundColor: 'white' }} onClick={downloadReport}>Download</Button>,
          <Button key="3" type="primary" onClick={toggleReportModal}>
            {t('Cancel')}
          </Button>,
        ]}
      >
        <div id="report-view" dangerouslySetInnerHTML={{ __html: report + extendedReport }} />
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    userRoles: state.userData.userRoles,
    workshop: state.userData.workshop,
    configData: state.userData.configData,
  };
}

export default connect(mapStateToProps, {})(PendingPayment);
